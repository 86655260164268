import { WiRaindrop, WiStrongWind, WiCloudy, WiHumidity } from 'react-icons/wi';
import { FaHandHoldingWater } from 'react-icons/fa';
import { getDisplayPrecipitation } from '../../utils/WeatherUtils';
import { weatherCodesMap } from '../Forecast/WeatherIcons';
import { HourlyForecast } from '../../types/Forecast';
import './HourlyForecast.css';
import StarsComponent from '../common/Stars';
import { useRecoilValue } from 'recoil';
import { unitPreferencesState, precipUnitSelector, speedUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';

type HourlyForecastCardProps = {
    forecast: HourlyForecast,
    card?:any
    displayAreaName?: boolean;
    isBestTimeToClimb?: boolean;
    className?: string;
    currentWeather?: boolean;
    overImage?: boolean;
  }


export default function HourlyForecastCardComponent({ forecast, card, displayAreaName=false, isBestTimeToClimb=false, className='', currentWeather=false, overImage=false }: HourlyForecastCardProps) {
    const temperatureUnit = useRecoilValue(temperatureUnitSelector);
    const speedUnit = useRecoilValue(speedUnitSelector);
    const precipUnit = useRecoilValue(precipUnitSelector);
    const {metricUnits} = useRecoilValue(unitPreferencesState);
    const isNightClass = !forecast.isDay ? 'bg-gray-light' : '';
    const dependentClass = overImage ? 'hourly-over-image' : 'card-layout';
    const isBestTimeToClimbClass = isBestTimeToClimb ? 'border-4 border-orange border-opacity-75' : '';
    return (
        <div ref={card} className={`${dependentClass} h-full ${className} ${isNightClass} ${isBestTimeToClimbClass} min-w-[15rem]` } key={`hourly-forecast-${forecast.time}`}>
            {displayAreaName && forecast.areaName && (
                <div className='card-title flex items-center'>
                    <span className='line-clamp-2 block'>{forecast.areaName}</span>
                </div>
            )}
           
            <div>
                <span className="card-time">{currentWeather ? 'Current Weather' : forecast.displayTime}</span>
            </div>
            {forecast.weatherCode !== undefined && (
                <div className="weather-icon-position">
                    {weatherCodesMap(forecast.isDay).get(forecast.weatherCode.toString())}
                </div>
            )}
            <p className="weather-description">{forecast.weatherCodeDescription}</p>
            <div className={`${currentWeather ? 'mt-3' : '' } temperature`}>
                {forecast.temperature}{temperatureUnit}
            </div>
            <div className="feels-like-heading">
                Feels Like {forecast.apparentTemperature}{temperatureUnit}
            </div>
            <div className={`${currentWeather ? 'mt-3' : '' } rain-wind-hum-cloud-score-div`}>
                <div className="icons-styling-hourly">
                    <FaHandHoldingWater size={20}/>
                    &nbsp;{forecast.relativeHumidity}%
                </div>
                <div className="icons-styling-hourly">
                    <WiStrongWind size={30}/>
                    {forecast.windSpeed} {speedUnit} {forecast.windDirection}
                </div>
            </div>
            <div className="rain-wind-hum-cloud-score-div">
                <div className="icons-styling-hourly">
                    <WiRaindrop size={30}/>
                    {getDisplayPrecipitation(forecast.quantitativePrecipitation, metricUnits)} {precipUnit}
                </div>
                {forecast.precipitationProbability !== null && (
                    <div className="icons-styling-hourly">
                        <WiHumidity size={30}/>
                        {forecast.precipitationProbability}%
                    </div>
                )}
                <div className="icons-styling-hourly">
                    <WiCloudy size={30}/>
                    {forecast.cloudCover}%
                </div>
            </div>
            <div className={currentWeather ? 'mt-3' : '' }>
                {forecast.climbit && forecast.climbit.climbitStars !== undefined && (              
                    <span>
                        <StarsComponent value={forecast.climbit.climbitStars} max={5}/>
                    </span>                    
                )}
            </div>
        </div>

    );
}