import Title from '../components/common/Title';

export default function PrivacyPolicy() {
    document.title = 'Privacy Policy';
    return   (
        <div className="min-h-screen flex flex-col">
            <div className="bg-white">
                <div className='mt-4'>
                    <Title title="Privacy Policy"/>
                </div>
                <div className="flex flex-col items-left px-5">
                    <h2 className="text-lg font-semibold mb-2">OUR PRIVACY COMMITMENT</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit (“our,” "we," or "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, protect, and share your information when you use Climbit services ("Services").
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    By accessing or using our Services, you acknowledge that you have read, understood, and agree to the practices and policies outlined in this Privacy Policy. Your use of Climbit services is subject to our Terms of Use, which incorporates this Privacy Policy.
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    If you have a disability and need this Privacy Policy in an alternative format, please contact us at climbitscore@gmail.com.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">Privacy Policy Overview</h2>
                    <p className="text-gray mb-6 ml-4">
                    This Privacy Policy applies to all personal data collected through Climbit Services, including websites, mobile applications, electronic communications, and other features or functionalities we offer.
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    Climbit acts as the data controller of your personal data and is subject to applicable privacy laws and regulations.                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 1 - PERSONAL DATA COLLECTION</h2>
                    <p className="text-gray mb-6 ml-4">
                    We collect personal data through various sources, including information you provide directly, data obtained from your device or internet service provider, and information obtained from third parties.
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    When you visit our Site, we automatically collect certain information about your device, including your web browser, IP address, time zone, and cookies installed on your device. Additionally, we collect information about your browsing activity on the Site, including web pages visited and interactions with the Site. We refer to this automatically-collected information as "Device Information."
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    We collect Device Information using technologies such as log files, web beacons, cookies, reCaptcha, tags, and pixels.                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 2 - TYPES OF PERSONAL DATA COLLECTED</h2>
                    <p className="text-gray mb-6 ml-4">
                    The personal data we collect may include identifying information, device and connection information, usage information, location information, tracking information, and data obtained from third parties.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 3 - PERSONAL DATA USAGE</h2>
                    <p className="text-gray mb-6 ml-4">
                    We use the Device Information collected to screen for potential risk and fraud, improve and optimize our Site, assess the success of marketing and advertising campaigns, and personalize user experiences.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 4 - SHARING PERSONAL DATA</h2>
                    <p className="text-gray mb-6 ml-4">
                    We may share your Personal Information with third parties to help us use your Personal Information, as described above. We also share Personal Information to comply with applicable laws and regulations, respond to lawful requests for information, protect our rights, and fulfill our contractual obligations.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 5 - TRANSFER OF PERSONAL DATA</h2>
                    <p className="text-gray mb-6 ml-4">
                    Your personal data may be transferred outside the European Economic Area (EEA) to jurisdictions with adequate protection measures or with your explicit consent.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 6 - ONWARD TRANSFERS</h2>
                    <p className="text-gray mb-6 ml-4">
                    Third parties receiving your personal data must adhere to privacy and security standards consistent with this Privacy Policy, and we ensure compliance through contractual obligations and notification requirements.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 7 - SECURITY</h2>
                    <p className="text-gray mb-6 ml-4">
                    We employ encryption, authentication measures, and regular security audits to protect your personal data, although we cannot guarantee absolute security.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 8 - DATA RETENTION</h2>
                    <p className="text-gray mb-6 ml-4">
                    We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Data retention periods vary based on the nature of the data and legal requirements.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 9 - PRIVACY CHOICES</h2>
                    <p className="text-gray mb-6 ml-4">
                    You have rights regarding your personal data, including access, correction, deletion, restriction of processing, objection, and data portability. You may exercise these rights by contacting us.
                    </p>
                    <p className="text-gray mb-6 ml-4">
                    California Residents — Your California Privacy Rights: California residents have additional rights under the California Consumer Privacy Act (CCPA). Please refer to our CCPA Privacy Notice to learn more about these rights and how we handle your personal data.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 10 - THIRD-PARTY INFORMATION COLLECTION</h2>
                    <p className="text-gray mb-6 ml-4">
                    Third-party sites, ad servers, and sponsors may collect information through Climbit services. We recommend reviewing their privacy policies.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 11 - THIRD PARTY AD SERVERS</h2>
                    <p className="text-gray mb-6 ml-4">
                    Third-party ad servers may use tracking technologies for advertising purposes, governed by their own privacy policies.                   
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 12 - CHILDREN'S PRIVACY AND INTERNATIONAL CONSIDERATIONS</h2>
                    <p className="text-gray mb-6 ml-4">
                    We do not knowingly collect personal data from individuals under 13 years of age. Climbit is headquartered in the United States, and your information may be transferred, collected, and stored in the U.S. or other countries where we operate.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 13 - UPDATING PERSONAL DATA AND CONTACTING US</h2>
                    <p className="text-gray mb-6 ml-4">
                    You can update or delete your personal data by accessing your account settings or contacting us directly at climbitscore@gmail.com 
                    </p>
                    <h2 className="text-lg font-semibold mb-2">SECTION 14 - CHANGES TO THIS PRIVACY POLICY</h2>
                    <p className="text-gray mb-6 ml-4">
                    We reserve the right to update this Privacy Policy, with changes posted on Climbit Services. Your continued use constitutes acceptance of the revised Policy. Material changes will be notified via email or other means.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">Effective Date</h2>
                    <p className="text-gray mb-6 ml-4">
                    This Privacy Policy is effective as of February 8, 2024.
                    </p>
                </div>
            </div>
        </div>
    );
}



