import { Helmet } from 'react-helmet';
import Areas from '../components/areas/Areas';

const AreasPage = () => {
    const currentUrl = window.location.href;
            
    return <div className='min-h-screen'>
        <Helmet>
            <link rel="canonical" href={currentUrl} />
        </Helmet>
        <Areas/>
    </div>;
};

export default AreasPage;