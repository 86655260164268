import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { defaultUserPreferences } from './resetPreferencesState';

const { persistAtom } = recoilPersist();


export const daytimeOnlyState = atom<boolean>({
    key: 'daytimeOnlyState',
    default: defaultUserPreferences.daytimeOnly,
    effects_UNSTABLE: [persistAtom]
});
