import React from 'react';
import { Link } from 'react-router-dom';

type CardProps = {
    title: string,
    link: string,
    children?: React.ReactNode,
    subTitle?: string,
  }

export default function CardComponent({title,link, children, subTitle}: CardProps) {
    return (
        <Link to={link} replace>
            <div className="parent-div">
                <div className="child-div" >
                    <h4 className="h4-title whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {title}
                    </h4>
                    {subTitle && (
                        <h6 className="h6-title">
                            {subTitle}
                        </h6>
                    )}
                    
                    {children}
                </div>
            </div>
        </Link>
    );
}