import { Area } from '../../types/Area';

type CardProps = {
    area: Area,
    showPath?: boolean
  }

export default function AreaCardContent({ area, showPath = false }: CardProps) {
    const path = area.path.replaceAll(',', '->');
  
    return (
        <dl>
            {showPath && (
                <div  className='text-sm font-medium text-gray-dark'>
                    {path}
                </div>
            )}
            
          
            <div className="bg-white w-full text-gray">
                <table className="w-full text-sm text-gray bg-white">
                    <tbody>
                        <tr className="flex justify-between bg-white border-b border-gray-light w-full">
                            <td className="text-base font-medium text-gray-dark whitespace-nowrap">Trad</td>
                            <td className="text-base text-gray-dark font-bold whitespace-nowrap w-1/2">{area.totalTradClimbs}</td>
                        </tr>
                        <tr className="flex justify-between bg-white border-b border-gray-light w-full">
                            <td className="text-base font-medium text-gray-dark whitespace-nowrap">Sport</td>
                            <td className="text-base text-gray-dark font-bold whitespace-nowrap w-1/2">{area.totalSportClimbs}</td>
                        </tr>
                        <tr className="flex justify-between bg-white border-b border-gray-light w-full">
                            <td className="text-base font-medium text-gray-dark whitespace-nowrap">Top Rope</td>
                            <td className="text-base text-gray-dark font-bold whitespace-nowrap w-1/2">{area.totalTopRopeClimbs}</td>
                        </tr>
                        <tr className="flex justify-between bg-white border-b border-gray-light w-full">
                            <td className="text-base font-medium text-gray-dark whitespace-nowrap">Boulders</td>
                            <td className="text-base text-gray-dark font-bold whitespace-nowrap w-1/2">{area.totalBoulderClimbs}</td>
                        </tr>
                        <tr className="flex justify-between bg-white border-b border-gray-light w-full">
                            <td className="text-base font-medium text-gray-dark whitespace-nowrap">Total</td>
                            <td className="text-base text-gray-dark font-bold whitespace-nowrap w-1/2">{area.totalClimbs}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </dl>
    );
}
