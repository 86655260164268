import { useLazyQuery } from '@apollo/client';
import { FaArrowRight } from 'react-icons/fa';
import { AREA_BY_PATH } from '../../graphql/areas/queries';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUrlSubdomain } from '../../utils/CommonUtils';


type AncestorLinksComponent = {
    path: string,
  }

export default function AncestorLinks({ path }: AncestorLinksComponent) {
    const navigate = useNavigate();
    const [getAncestorArea, { data }] = useLazyQuery(AREA_BY_PATH);
    const ancestors = path.split(',');
    // remove last element since it is the current area
    ancestors.pop();

    useEffect(() => {
        if (data && data.areaByPath && data.areaByPath.id) {
            navigate(`/area/${data.areaByPath.id}/${createUrlSubdomain(data.areaByPath.name)}`);
        }
    }, [data]);

    const goToAncestor = (index: number) => {
        const ancestorArray = ancestors.slice(0, index + 1);
        const ancestorString = ancestorArray.toString();
        getAncestorArea({ variables: { path: ancestorString } });
    };



    return (
        <div className='flex flex-row items-center flex-wrap pb-4 self-start ml-1' key='ancestor-links'>
            { ancestors.length > 0 && (
                <>
                    {ancestors.map((ancestor, index) => (
                        <div key={`ancestor-${index}`} className='flex flex-nowrap items-center'>
                            <p onClick={() => goToAncestor(index)} className='ancestor-link'>{ancestor}</p>
                            {index !== ancestors.length - 1 && (
                                <FaArrowRight size={13} key={`ancestor-arrow-${index}`}/>
                            )}
                        </div>
                        
                    ))}
                </>
            )}
        </div>

    );
}