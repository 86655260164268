import { atom } from 'recoil';
import { City } from '../types/City';

export const citiesState = atom<City[]>({
    key: 'citiesState',
    default: [],
});

export const cityState = atom<City>({
    key: 'cityState'
});