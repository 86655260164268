import { useRecoilState, useRecoilValue } from 'recoil';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import { useEffect, useRef, useState } from 'react';
import { defaultUserPreferences, resetPreferencesState } from '../../state/resetPreferencesState';

type PastPrecipHoursSelectorProps = {
    savePreferences: boolean,
}

export default function PastPrecipHoursSelector({savePreferences}: PastPrecipHoursSelectorProps) {
    const [pastPrecipHours, setPastPrecipHours] = useRecoilState(pastPrecipHoursState);
    const [number, setNumber] = useState(pastPrecipHours);
    const [error, setError] = useState<string | undefined>();
    const resetPreferences = useRecoilValue(resetPreferencesState);

    // Ref to store the latest latestNumber value
    const latestNumberRef = useRef<number | undefined>(undefined);
    latestNumberRef.current = number;

    // Ref to store the latest error value
    const latestErrorRef = useRef<string | undefined>(undefined);
    latestErrorRef.current = error;

    useEffect(() => {
        return () => {
            if (savePreferences === true) {
                const latestNumber = latestNumberRef.current;
                const latestError = latestErrorRef.current;
                if (latestError) {
                    setPastPrecipHours(pastPrecipHours);
                } else {
                    setPastPrecipHours(latestNumber);
                }
            }
        };  
    }, [savePreferences]);

    useEffect(() => {
        if (resetPreferences === true){
            setNumber(defaultUserPreferences.pastPrecipHours);
        }
    }, [resetPreferences]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        if (!isNaN(input) && input >= 0 && input <= 48) {
            setNumber(input);
            setError(undefined); // Clear error
        } else {
            setNumber(input);
            setError('Please enter a number between 0 and 48.');
        }
    };

    return (
        <>
            <div className="flex md:flex-row flex-col items-center md:justify-between md:space-y-0 space-y-1">
                <span className='font-semibold md:text-lg text-[1rem]'>Past Precip Hours To Consider</span>
                <input
                    type="number"
                    id="numberInput"
                    value={number}
                    onChange={(event) => handleChange(event)}
                    min={0}
                    max={48}
                    className="border border-gray rounded p-2 focus:ring-primary focus:border-primary font-semibold text-gray w-[4rem]"
                />
            </div>
            {error && <p className="text-red text-sm">{error}</p>}
        </>
        
        
    );
}
