import { gql } from '@apollo/client';


export const AREA_FRAGMENT = gql`
    fragment areaFragment on Area {
        id
        name
        timezone
        latitude
        longitude
        googleMaps
        mountainProject
        regionInfo
        imageUrl
        totalClimbs
        totalTopRopeClimbs
        totalBoulderClimbs
        totalTradClimbs
        totalSportClimbs
        isCountry
        isState
        sunShade
        isLeaf
        path
        ancestors
        children
        childrenList {
            id
            name
        }
    } 
`;

export const CITY_FRAGMENT = gql`
    fragment cityFragment on City {
        name
        timezone
        latitude
        longitude
        path
        country
        countryCode
        state    
        stateCode  
        googleMaps  
    } 
`;

export const LOCATION_FRAGMENT = gql`
    fragment locationFragment on Location {
        name
        timezone
        latitude
        longitude
        googleMaps
    } 
`;

export const AREA_ID_NAME_FRAGMENT = gql`
    fragment areaIdNameFragment on Area {
        id
        name
        path
    } 
`;