import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useRecoilState } from 'recoil';
import { sunShadeState } from '../../state/sunShadeState';
import { useEffect, useState } from 'react';
import InfoButton from '../buttons/InfoButton';

type SunShadeProps = {
    areaId: string;
  }


export default function SunShadeComponent({areaId}: SunShadeProps) {
    const [sunShade, setSunShade] = useRecoilState(sunShadeState);
    const [sliderValue, setSliderValue] = useState<number>(sunShade[areaId] || 0);

    const marks = {    
        0: <span className={`text-gray ${sliderValue === 0 ? 'font-bold md:text-sm text-xs' : ''}`}>Unknown</span>,
        1: <span className={`text-gray ${sliderValue === 1 ? 'font-bold md:text-sm text-xs' : ''}`}>All Day Sun</span>,
        2: <span className={`text-gray ${sliderValue === 2 ? 'font-bold md:text-sm text-xs' : ''}`}>AM Shade</span>,
        3: <span className={`text-gray ${sliderValue === 3 ? 'font-bold md:text-sm text-xs' : ''}`}>PM Shade</span>,
        4: <span className={`text-gray ${sliderValue === 4 ? 'font-bold md:text-sm text-xs'  : ''}`}>All Day Shade</span>,
    };

    useEffect(() => {
        // Set slider value when the component mounts
        if (sunShade[areaId] !== undefined) {
            setSliderValue(sunShade[areaId]);
        }
    }, [areaId, sunShade]);


    // Function to update the sun shade state value for a specific area Id
    const updateShadeSun = (value: number) => {
        setSunShade(prevState => ({
            ...prevState,
            [areaId]: value
        }));
    };

    const handleSliderChange = (value: number | number[]) => {
        let sunShadeValue = 0;
        // If it's already a singular number, return it
        if (typeof value === 'number') {
            sunShadeValue = value;
        }
        // If it's an array, return the first element
        else if (Array.isArray(value) && value.length > 0) {
            sunShadeValue = value[0];
        }
        setSliderValue(sunShadeValue);
    };

    const handleSliderComplete = (value: number | number[]) => {
        let sunShadeValue = 0;
        // If it's already a singular number, return it
        if (typeof value === 'number') {
            sunShadeValue = value;
        }
        // If it's an array, return the first element
        else if (Array.isArray(value) && value.length > 0) {
            sunShadeValue = value[0];
        }
        updateShadeSun(sunShadeValue);
    };

    return (
        <div className='flex w-full justify-center items-center'>
            <div className='md:w-[20%] w-[60%] md:pr-[1rem] md:-mt-4 md:mb-1 mb-6 mt-2 md:min-w-[22rem] min-w-[19rem] pr-[.5rem]'>
                <div className='flex flex-col items-center'>
                    <div className='flex flex-row items-center space-x-2 justify-center pb-2'>
                        <div>
                            <InfoButton modalContent={
                                <div>
                                    <h2 className="text-lg font-semibold text-black mb-4 mx-6">Do you know how much shade this area gets?</h2>
                                    <p className='text-left mx-4'>Sun/Shade Settings:</p>
                                    <ul className='list-disc mx-8 text-left'>
                                        <li>
                                            <strong>All Day Sun:</strong> Full sun exposure. "Feels like" temperature includes solar radiation and UV index.
                                        </li>
                                        <li>
                                            <strong>AM Shade:</strong> Shade until noon. Morning solar radiation and UV index are not included in the "feels like" temperature.
                                        </li>
                                        <li>
                                            <strong>PM Shade:</strong> Shade after noon. Afternoon solar radiation and UV index are not included in the "feels like" temperature.
                                        </li>
                                        <li>
                                            <strong>All Day Shade:</strong> No direct sun. Solar radiation and UV index are excluded from the "feels like" temperature all day.
                                        </li>
                                    </ul>
                                    <br/>
                                    <p className='text-left mx-4'>Which to choose:</p>
                                    <ul className='list-disc mx-8  text-left'>
                                        <li>
                                            South-facing crags: All Day Sun
                                        </li>
                                        <li>
                                            West-facing crags: AM Shade
                                        </li>
                                        <li>
                                            East-facing crags: PM Shade
                                        </li>
                                        <li>
                                            North-facing crags: All Day Shade
                                        </li>
                                    </ul>
                                </div>
                            }></InfoButton>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-xs font-semibold items-center text-center'>Do you know how much shade this area gets?</p>
                        </div>

                    </div>
                    <Slider 
                        value={sliderValue}
                        min={0}
                        marks={marks}
                        dotStyle={{ 
                            borderColor: '#808080',
                            height: 12,
                            width: 12
                        }}
                        railStyle={{ marginTop: -2, background: '#808080' }}
                        handleStyle={{
                            height: 20,
                            width: 20,
                            marginTop: -10,
                            backgroundColor: 'white',
                            border: '3px solid #43A9CB',
                            opacity: 1
                        }}
                        step={1}     
                        max={4} 
                        included={false}
                        className='whitespace-nowrap custom-slider mb-2'
                        onChange={((value) => handleSliderChange(value) )}
                        onChangeComplete={((value) => handleSliderComplete(value))}
                    />

                </div>
                
            </div>
        </div>
       
        
    );
}