import Title from '../components/common/Title';
import CompareComponent from '../components/compare/Compare';


const ComparePage = () => {
    document.title='Compare Areas | Rock Climbing Weather Forecasts';
    return (
        <div className="page-background min-h-screen">
            <header>
                <div className='flex flex-row justify-center md:justify-start mt-4'>
                    <Title title='Compare Areas'/>
                </div>
            </header>
            <div className="page-background">
                <CompareComponent/>
            </div>
            <div className="flex justify-center mb-4">
                <a href="https://open-meteo.com/" target="_blank" className="text-primary no-underline hover:underline hover:text-primary-dark text-xs">Weather data by Open-Meteo.com</a>
            </div> 
        </div>

    );
};

export default ComparePage;