import {
    useState, Fragment, useRef
} from 'react';
import { useRecoilState } from 'recoil';
import { Combobox, Transition } from '@headlessui/react';
import { compareFavoritesState } from '../../state/areasState';
import { AreaIdName } from '../../types/Area';
import { FaHeart } from 'react-icons/fa6';
import { CompareFavorite } from '../../types/Favorites';

type CompareFavoritesComboboxProps = {
    onChange: (selectedCompareFavoriteAreasIdName: AreaIdName[]) => void;
    placeholderText: string;
    className?: string;
    blur?: () => void;
    autoFocus?: boolean;
}



export default function CompareFavoritesComboboxComponent({onChange, placeholderText, blur, className: position = 'relative', autoFocus=false}: CompareFavoritesComboboxProps){
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [compareFavorites] = useRecoilState(compareFavoritesState);
    const [query, setQuery] = useState('');

    const [selectedCompareFavorite, setSelectedCompareFavorite] = useState<CompareFavorite | null>(null);

    const handleChange = (compareFavorite: CompareFavorite | null) => {
        // Blur the ComboboxInput after a short delay
        setTimeout(() => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        }, 10);
      
        setQuery('');
        if (compareFavorite) {
            setSelectedCompareFavorite(compareFavorite);
            onChange(compareFavorite.areasIdName);
        }
    };

    const filteredCompareFavorites =
        query === '' 
            ? []
            : compareFavorites.filter((compareFavorite: CompareFavorite) => {
                return compareFavorite.name.toLowerCase().includes(query.toLowerCase());
            }).map((compareFavorite: CompareFavorite) => {
                return compareFavorite;
            });

        

    return (
        <div onBlur={blur} className={`w-[20rem] ${position}`}>
            <Combobox value={selectedCompareFavorite} onChange={handleChange} immediate={true}>
                <div className="mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            ref={inputRef}
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-dark focus:ring-0"
                            displayValue={(areaIdName: AreaIdName) => areaIdName ? areaIdName.name : '' }
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder={placeholderText}
                            autoFocus={autoFocus}
                            autoComplete="off" 
                        />
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => {setQuery(''); setSelectedCompareFavorite(null);}}
                    >   
                        <Combobox.Options className="absolute mt-1 max-h-60 max-w-[20rem] md:max-w-[30rem] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            <div>
                                <p className='font-bold border-b-2 border-gray-light text-center'>Favorites</p>

                                {query === '' && compareFavorites.map((compareFavorite: CompareFavorite) => (
                                    <Combobox.Option
                                        key={`compare-favorite-${compareFavorite.name}`}
                                        className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                            }`
                                        }
                                        value={compareFavorite}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <div
                                                    className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                                >
                                                    <span className="font-bold flex flex-row items-center"> 
                                                        <FaHeart className='fill-red mr-1'/> 
                                                        <div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>{compareFavorite.name}</div>
                                                    </span>
                                                    <ul className='flex flex-col'>
                                                        {compareFavorite.areasIdName.map((areaIdName) => (
                                                            <li key={`favorite-list-${areaIdName.name}`}>
                                                                {areaIdName.name}
                                                            </li>
                                                        )
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}    
                                {filteredCompareFavorites.length === 0 && query !== '' && (
                                    <div className="relative cursor-default select-none px-4 text-gray">
                                    Nothing found.
                                    </div>
                                )} 
                                {filteredCompareFavorites.length > 0 &&
                                filteredCompareFavorites.map((compareFavorite: CompareFavorite) => (
                                    <Combobox.Option
                                        key={`compare-favorite-${compareFavorite.name}`}
                                        className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                            }`
                                        }
                                        value={compareFavorite}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <div
                                                    className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                                >
                                                    <span className="font-bold flex flex-row items-center"> 
                                                        <FaHeart className='fill-red mr-1'/> 
                                                        <div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>{compareFavorite.name}</div>
                                                    </span>
                                                    <ul className='flex flex-col'>
                                                        {compareFavorite.areasIdName.map((areaIdName) => (
                                                            <li key={`favorite-list-${areaIdName.name}`} className='flex'>
                                                                {areaIdName.name}
                                                            </li>
                                                        )
                                                        )}
                                                    </ul>
                                                    
                                                </div>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                                }
                            </div>
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}