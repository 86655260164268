import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';


type DateButtonProps = {
    currentIndex: number,
    date: string,
    length: number,
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

export default function DateButtons({ currentIndex, setCurrentIndex, date, length }: DateButtonProps) {
    const setNextDay = () => {
        setCurrentIndex(currentIndex + 1);
    };
    const setPrevDay = () => {
        setCurrentIndex(currentIndex - 1);
    };
  
    return (
        <div className="flex flex-row items-center justify-center pb-2">
            <button
                onClick={setPrevDay}
                className={currentIndex === 0 ? 'invisible' : 'visible'}
            >
                <FaChevronCircleLeft
                    size={18}
                    className="basis-4 text-2xl icon-btn mr-5"
                />
            </button>

            {date && (
                <div className="basis-32">
                    <h4
                        className={
                            'h4-font-color text-2xl whitespace-nowrap w-full text-center'
                        }
                    >
                        {date}
                    </h4>
                </div>
            )}
            <button
                className={
                    currentIndex < length - 1
                        ? 'visible'
                        : 'invisible'
                }
                onClick={setNextDay}
            >
                <FaChevronCircleRight
                    size={18}
                    className="text-2xl icon-btn ml-5 basis-4"
                />
            </button>
        </div>
    );
}
