import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const defaultUserPreferences = {
    daytimeOnly: false,
    weatherModel: 'best_match',
    pastPrecipHours: 12,
    unitPreferences: {
        metricUnits: false,
        temperaturePreferences: {
            ideal: {
                min: 40,
                max: 55
            },
            minMax: {
                min: 30,
                max: 78
            } 
        }
    }  
};

export const resetPreferencesState = atom<boolean>({
    key: 'resetPreferencesState',
    default: false,
    effects_UNSTABLE: [persistAtom]
});
