// import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { compareAreasIdNameState } from '../../state/areasState';
import CompareAreas from './CompareAreas';
import { Toaster } from 'react-hot-toast';

export const maxAreas = 4;

const CompareComponent = () => {
    const [compareAreasIdName] = useRecoilState(compareAreasIdNameState);
    return (
        <div className='flex w-full min-h-screen'>
            <div className="w-full">
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName="toast"
                    containerStyle={{}}
                    toastOptions={{
                        className: '',
                        duration: 3500,
                        style: {
                            background: '#363636',
                            color: '#fff',
                        }
                    }}/>      
                <CompareAreas 
                    areaIds={compareAreasIdName.map((area)=>area.id)}
                />
            </div>
        </div>
    );
};

export default CompareComponent;