import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { AREA_ID_NAME_BY_LAT_LONG, LOCATION_DAILY_HOURLY_CURRENT_FORECAST } from '../../graphql/areas/queries';
import { singleAreaState } from '../../state/areasState';
import ClimbitLogo from '../../images/climbitlogo.png';
import Loading from '../common/Loading';
import { navState } from '../../state/navState';
import MissingPageComponent from '../common/MissingPage';
import { HourlyForecast, DailyForecast } from '../../types/Forecast';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { sunShadeState } from '../../state/sunShadeState';
import { useQuery } from '@apollo/client';
import DailyForecastWidgetCardComponent from '../cards/DailyForecastWidgetCard';
import HourlyForecastTable from '../Forecast/HourlyForecastTable';
import { createUniqueId } from '../../utils/CommonUtils';
import { findMinMaxForecastValues } from '../../utils/WeatherUtils';

const DailyForecastWidget = () => {
    const params: any = useParams();
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const { temperaturePreferences, metricUnits } = useRecoilValue(unitPreferencesState);
    const [location, setLocation] = useRecoilState<any>(singleAreaState(params.id));
    const [areaUrl, setAreaUrl] = useState(`${window.location.origin}/location/${params.latitude}/${params.longitude}`);
    const [selectedGraph, setSelectedGraph] = useState<string>('climbit');
    const sunShade = useRecoilValue(sunShadeState);

    const urlLocation = useLocation();

    // Function to parse query parameters
    const useUrlQuery = () => {
        return new URLSearchParams(urlLocation.search);
    };
  
    const query = useUrlQuery();
    const hasMetricParam = query.get('metric');
    const isMetric = query.get('metric') === 'true';


    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]);

    const { data, loading, error, refetch: refetchQuery } = useQuery(LOCATION_DAILY_HOURLY_CURRENT_FORECAST, {
        variables: {
            name: createUniqueId(Number(params.latitude), Number(params.longitudes)),
            latitude: params.latitude,
            longitude: params.longitude,
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits: hasMetricParam ? isMetric : metricUnits,
            sunShade: sunShade[params.id] || 0
        }
    });

    const { data: foundArea } = useQuery(AREA_ID_NAME_BY_LAT_LONG, {
        variables: {
            name: params.name || '',
            latitude: params.latitude,
            longitude: params.longitude,
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShade: sunShade[params.id] || 0
        }
    });


    const resetNavState = useResetRecoilState(navState);
    const [dailyForecast, setDailyForecast] = useState<DailyForecast[]>([]);
    const [currentHourlyForecast, setCurrentHourlyForecast] = useState<HourlyForecast | null>(null);
    useEffect(() => {
        resetNavState();
        const isValid = !!data?.location?.forecasts;
        if (isValid) {
            setLocation(data.location);
            if (data.location.forecasts.dailyForecast) setDailyForecast(data.location.forecasts.dailyForecast);
            if (data.location.forecasts.currentWeather) setCurrentHourlyForecast(data.location.forecasts.currentWeather);
        }
    }, [data]);

    useEffect(() => {
        resetNavState();
        const isValid = foundArea && foundArea.areaByLatLong && foundArea.areaByLatLong.id;
        if (isValid) {
            setAreaUrl(`${window.location.origin}/area/${foundArea.areaByLatLong.id}`);
        }
    }, [foundArea]);

    if (isNaN(params.latitude), isNaN(params.longitude)) return <MissingPageComponent />;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent />;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent />;
    if (!location && !loading) return <MissingPageComponent />;

    return (
        <div className='h-full w-full'>
            <div className="flex justify-center mb-4 flex-col items-center">
                <div className="bg-white-secondary h-[32rem] w-[38rem] overflow-hidden border-2 rounded-lg shadow-md cursor-pointer"  onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        window.open(areaUrl, '_blank');
                        e.stopPropagation();
                    }
                }}>
                    {loading && (
                        <Loading type='flex' size='w-full h-full'/>
                    )}
                    {!loading && (
                        <div className="overflow-y-auto h-full p-4" onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                window.open(areaUrl, '_blank');
                                e.stopPropagation();
                            }
                        }}>
                            {currentHourlyForecast && (
                                <div className='w-full pb-4 cursor-default'>
                                    <HourlyForecastTable
                                        forecasts={[currentHourlyForecast]}
                                        currentWeather={true}
                                        simple={true}
                                        isMetric={isMetric}
                                    /> 
                                </div>
                 
                            )}
                            {dailyForecast && (
                                <div className="flex flex-col space-y-2 items-center justify-center" onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        window.open(areaUrl, '_blank');
                                        e.stopPropagation();
                                    }
                                }} >
                                    {dailyForecast.map((forecast: DailyForecast) => (
                                        <div key={forecast.date} className='cursor-default'>
                                            <DailyForecastWidgetCardComponent
                                                hourlyForecasts={data.location.forecasts.hourlyForecast?.filter(
                                                    ({ date }: HourlyForecast) => date === forecast.date
                                                )}
                                                
                                                id={forecast.areaId}
                                                setSelectedGraph={setSelectedGraph}
                                                selectedGraph={selectedGraph}
                                                forecast={forecast}
                                                isMetric={hasMetricParam ? isMetric : metricUnits}
                                                className='snap-normal snap-center'
                                                areaName={forecast.areaName}
                                                forecastMinMaxValues={findMinMaxForecastValues(data.location.forecasts.hourlyForecast)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    
                </div>
                <div className='mt-3 flex flex-row space-x-3 cursor-pointer' onClick={(event) => {
                    window.open(areaUrl, '_blank');
                    event.stopPropagation();
                }}>
                    <a target="_blank" className="text-primary no-underline hover:underline hover:text-primary-dark text-lg">Powered by ClimbitScore.com</a>
                    <img className='h-[32px] inline-block' src={ClimbitLogo} alt='climbit logo' />
                </div>
            </div>     
        </div>
        
    );
};

export default DailyForecastWidget;
