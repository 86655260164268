import { ApolloClient, InMemoryCache } from '@apollo/client';


const uri = process.env.REACT_APP_BACKEND_URL || 'https://climbitscore.com/graphql';

const client = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
});

export default client;

