import { Helmet } from 'react-helmet';
import Location from '../components/area/Location';
import { useParams } from 'react-router-dom';

const LocationPage = () => {
    const { latitude, longitude } = useParams();
    return (
        <div className='min-h-screen'>
            <Helmet>               
                <meta property="og:title" content={ latitude && longitude ? `${latitude}, ${longitude} | Climbit Weather Forecast` :  'Climbit Weather Forecast'} />
                <meta property="og:description" content={latitude && longitude ? `Climbing specific weather forecast for ${latitude}, ${longitude}` : 'Climbing specific weather forecast'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="description" content={latitude && longitude ? `Climbing specific weather forecast for ${latitude}, ${longitude}.` : 'Climbing specific weather forecast'} />
                <title>{latitude && longitude ? `${latitude}, ${longitude} | Climbit Weather Forecast` : 'Climbit Weather Forecast'}</title>
            </Helmet>
            <Location/>
            <div className="flex justify-center mb-4">
                <a href="https://open-meteo.com/" target="_blank" className="text-primary no-underline hover:underline hover:text-primary-dark text-xs">Weather data by Open-Meteo.com</a>
            </div>        
        </div>
    );
};

export default LocationPage;