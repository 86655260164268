// import { useState } from 'react';
import {
    BsThermometerHalf, BsThermometerHigh, BsThermometerLow
} from 'react-icons/bs';
import { PiChartLineBold } from 'react-icons/pi';
import { getDisplayPrecipitation } from '../../utils/WeatherUtils';
import { weatherCodesMap } from '../Forecast/WeatherIcons';
import { DailyForecast, HourlyForecast } from '../../types/Forecast';
import './DailyForecast.css';
import StarsComponent from '../common/Stars';
import { useNavigate } from 'react-router-dom';
import ActionsButton from '../common/Actions';
import { AreaIdName } from '../../types/Area';
import { WiRaindrop } from 'react-icons/wi';
import { useRecoilValue } from 'recoil';
import { unitPreferencesState, precipUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';
import { _getCompareLineData, createUniqueLabels, createUrlSubdomain, lineColors, sortDisplayDates } from '../../utils/CommonUtils';
import ReactCardFlip from 'react-card-flip';
import { LineChartData } from '../../types/LineChart';
import LineChartCard from './LineChartCard';
import { useState } from 'react';
import { FaCloudSun } from 'react-icons/fa6';

type DailyForecastCardMobileProps = {
    forecast: DailyForecast;
    bestTimeToClimb?: HourlyForecast;
    hourlyForecasts?: HourlyForecast[];
    id: string;
    areaName: string;
    displayAreaName?: boolean;
    displayAreaLink?: boolean;
    displayDate?: boolean;
    onRemoveCrag?: (event: any, areaIdName: AreaIdName) => void;
    className?: string;
    onClick?: (value: string | number) => void;
    displayActionsButton?: boolean;
    card?:any;
};

export default function DailyForecastCardMobileComponent({card,forecast, areaName, displayAreaName = false, displayDate = true, displayAreaLink = false, id, onRemoveCrag, bestTimeToClimb, hourlyForecasts, onClick = () => { return; }, displayActionsButton = false, className = ''}: DailyForecastCardMobileProps) {
    const navigate = useNavigate(); 
    const {metricUnits} = useRecoilValue(unitPreferencesState);
    const temperatureUnit = useRecoilValue(temperatureUnitSelector);
    const precipUnit = useRecoilValue(precipUnitSelector);
    const [isFlipped, setIsFlipped] = useState(false);
    const uniqueLabels: string[] = hourlyForecasts ? createUniqueLabels(hourlyForecasts, 'displayTime') : [];
    const labels:string[] = hourlyForecasts ? sortDisplayDates(uniqueLabels) : [];
    const lineData: LineChartData[] = hourlyForecasts ? _getCompareLineData(hourlyForecasts, 'climbit', 'climbitStars', undefined, 'displayTime', labels, lineColors.climbitScoreLineColor) : [];
    return(
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div>
                <div ref={card} className={`card-layout-mobile mt-1 py-2 px-2 ${className} min-w-full h-[10rem] relative`} key={`daily-forecast-${forecast.areaId}-${forecast.time}`} onClick={(e) => { e.stopPropagation(); onClick(id); }}>
                    {displayActionsButton && (
                        <ActionsButton className='compare-actions-btn' alignment='right-[6px] top-[4px]'>
                            {onRemoveCrag && (
                                <div className='font-semibold' onClick={(event) =>{ onRemoveCrag(event, {name: forecast.areaName, id: forecast.areaId, path: ''}); event.stopPropagation();}}>
                            Remove
                                </div>
                            )}
                            {displayAreaLink && 
                    <div className='font-semibold' onClick={(event) =>{ event.stopPropagation(); navigate(`/area/${id}/${createUrlSubdomain(areaName)}`);}}>
                        Area Page
                    </div>   
                            }        
                        </ActionsButton>
                    )}
                    {hourlyForecasts && (
                        <button
                            className="hover:scale-105 absolute left-[6px] top-[4px] text-primary hover:text-primary-dark text-xl cursor-pointer z-10"
                            onClick={(e) => {
                                setIsFlipped(!isFlipped);
                                e.stopPropagation();
                            }}
                        >
                            <PiChartLineBold size={26} />
                        </button>
                    )}
       
       
                    {displayAreaName && areaName &&  (
                        <div className='w-full h-[3rem] items-center justify-center text-center flex' >
                            <h4 className="w-[80%] text-lg font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
                                {areaName}
                            </h4>
                        </div>
                    )}
                    {displayDate && (
                        <div className='card-date'>
                            <span>{forecast.displayDate}</span>
                        </div>
                    )}

                    <div className='flex flex-row w-full items-center justify-center'>
                        <div className='flex w-1/3 justify-center flex-col items-center'>
                            {(forecast && forecast.weatherCodes) && (
                                <div className='w-full'>
                                    {(forecast.weatherCodes.length === 2) && (
                                        <div className=' justify-center'>
                                            <div className='flex flex-row justify-center w-full items-center'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='flex flex-col justify-center items-center'>
                                                        <div className='w-13 h-13 flex'>
                                                            {weatherCodesMap(true, 50).get(forecast?.weatherCodes[0]?.toString())}
                                                        </div>
                                                        <p>AM</p> 
                                                    </div>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='flex flex-col justify-center items-center'>
                                                        <div className='w-13 h-13 flex'>
                                                            {weatherCodesMap(true, 50).get(forecast?.weatherCodes[1]?.toString())}
                                                        </div>
                                                        <p>PM</p> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(forecast.weatherCodes.length === 1) && (
                                        <div className='flex justify-center items-center'>
                                            <div className='w-13 h-13 flex'>
                                                {weatherCodesMap(true, 50).get(forecast?.weatherCodes[0]?.toString())}
                                            </div>
                                        </div>
                                    )}
                                </div> 
                            )}
                    
                    
                        </div>
                        <div className='flex flex-row w-1/3 items-center justify-center pr-4'>
                            {forecast.maxTemperature < 45 && (
                                <div>
                                    <BsThermometerLow size={30}/>
                                </div>
                            )}
                            {forecast.maxTemperature >= 45 && forecast.maxTemperature <= 75 && (
                                <div>
                                    <BsThermometerHalf size={30}/>
                                </div>
                            )}
                            {forecast.maxTemperature > 75 && (
                                <div>
                                    <BsThermometerHigh size={30}/>
                                </div>
                            )}
                    
                            <div className='flex flex-col items-center'>
                                <div className='temperature-high-low-mobile'>
                                    {Math.round(forecast.maxTemperature)}{temperatureUnit}
                                </div>
                                <div className="h-0.5 w-full bg-gray"></div>
                                <div className='temperature-high-low-mobile'>
                                    {Math.round(forecast.minTemperature)}{temperatureUnit}
                                </div>
                        
                            </div>
                        </div>
                        <div className='flex flex-row w-1/3 items-center justify-center pr-4'>
                            <div>
                                <WiRaindrop size={30}/>
                            </div>
                            <div className='flex flex-col items-center'>
                                <div className='temperature-high-low-mobile'>
                                    {getDisplayPrecipitation(forecast?.quantitativePrecipitation, metricUnits)}{precipUnit}
                                </div>
                                <div className="h-0.5 w-full bg-gray"></div>
                                <div className='temperature-high-low-mobile'>
                                    {forecast?.precipitationHours} hr(s)
                                </div>
                            </div>
                        </div>
                    </div>
                    {bestTimeToClimb?.climbit.climbitStars !== undefined && (
                        <div className='flex flex-row space-x-4'>
                            <span>
                                <p className='best-time-to-climb-mobile'>
                                Best Time {bestTimeToClimb.displayTime}
                                </p>
                            </span>  
                            <span>
                                <StarsComponent value={bestTimeToClimb.climbit.climbitStars} max={5} size={30}/>
                            </span>  
                        </div>                                       
                    )}
                </div>
            </div>
            <div ref={card} className={`card-layout-mobile mt-1 py-2 px-2 ${className} min-w-full h-[10rem] relative`} key={`daily-forecast-${forecast.areaId}-${forecast.time}`} onClick={(e) => { e.stopPropagation(); onClick(id); }}>
                {displayActionsButton && (
                    <ActionsButton className='compare-actions-btn z-10' alignment='right-[6px] top-[4px]'>
                        {onRemoveCrag && (
                            <div className='font-semibold' onClick={(event) =>{ onRemoveCrag(event, {name: forecast.areaName, id: forecast.areaId, path: ''}); event.stopPropagation();}}>
                            Remove
                            </div>
                        )}
                        {displayAreaLink && 
                    <div className='font-semibold' onClick={(event) =>{ event.stopPropagation(); navigate(`/area/${id}/${createUrlSubdomain(areaName)}`);}}>
                        Area Page
                    </div>   
                        }        
                    </ActionsButton>
                )}
                {hourlyForecasts && (
                    <button
                        className="hover:scale-105 absolute left-[6px] top-[4px] text-primary hover:text-primary-dark text-xl cursor-pointer z-10"
                        onClick={(e) => {
                            setIsFlipped(!isFlipped);
                            e.stopPropagation();
                        }}
                    >
                        <FaCloudSun size={26} />
                    </button>
                )}
       
       
                {displayAreaName && areaName &&  (
                    <div className='w-full overflow-hidden h-[3rem]' >
                        <h4 className="w-[95%] text-lg font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
                            {areaName}
                        </h4>
                    </div>
                )}
                {displayDate && (
                    <div className='card-date'>
                        <span>{forecast.displayDate}</span>
                    </div>
                )}
                {hourlyForecasts && (
                    <div className='max-w-full min-w-full'>
                        <div className="flex w-full">
                            {lineData && labels && (
                                <LineChartCard
                                    labels={labels}
                                    title={`${areaName}: Climbit Score`}
                                    lineData={lineData}  
                                    yMin={0}
                                    yMax={5}
                                    maintainAspectRatio={false}
                                    height='h-[9.5rem]'
                                    shadow={false}
                                    legend={false}
                                    paddingClass=''
                                    labelSuffix='★'
                                    titleFont={15}
                                    axisLabelFont={8}
                                />
                            )} 
                        </div>
                    </div>  
                )}
            </div>
            
        </ReactCardFlip>

    );



    
}
