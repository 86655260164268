import { gql } from '@apollo/client';

export const HOURLY_FORECAST_FRAGMENT = gql`
    fragment hourlyForecastFragment on HourlyForecast {
        areaName,
        areaId,
        time,
        displayTime,
        date,
        displayDate,
        temperature,
        apparentTemperature,
        relativeHumidity,
        windSpeed,
        cloudCover,
        precipitationProbability,
        dewpoint,
        quantitativePrecipitation,
        weatherCode,
        weatherCodeDescription,
        windDirection,
        uvIndex,
        isDay,
        sunshineDurationMinutes,
        climbit {
            climbitScore,
            climbitScorePercentage,
            climbitStars
        }
    }  
`;

export const DAILY_FORECAST_FRAGMENT = gql`
    fragment dailyForecastFragment on DailyForecast {
        areaId
        areaName
        weatherCodes
        weatherCodeDescriptions
        maxTemperature
        minTemperature
        minApparentTemperature
        maxApparentTemperature
        sunset
        sunrise
        precipitationHours
        quantitativePrecipitation
        time
        date
        displayDate
    }  
`;

export const BEST_TIMES_TO_CLIMB_FRAGMENT = gql`
    fragment bestTimesToClimbFragment on Forecasts {
       bestTimesToClimb {
            dates
            hourlyForecast {
                ...hourlyForecastFragment
            }  
       }   
    } 
    ${HOURLY_FORECAST_FRAGMENT}
`;

export const PAST_PRECIP_FRAGMENT = gql`
    fragment pastPrecipFragment on Forecasts {
        pastPrecip {
            last24
            last48
            lastPrecip {
                amount
                time
            }
        } 
    } 
`;