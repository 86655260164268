import { ListItem } from '../../types/ListItem';

export interface item {
    title: string;
    links: string
}

type ListProps = {
    items: ListItem[]
  }

export default function List({ items }: ListProps) {
    return (
        <div className="w-90 text-sm font-medium text-gray-dark bg-white border border-gray-light rounded-lg overflow-hidden">
            {items && items.map((item: ListItem) => (
                <div key={`list-${item.link}`}>
                    {item.title && item.title.toLocaleLowerCase() !== 'unknown' && (
                        <a
                            href={item.link}
                            key={`list-item-${item.title}`}
                            aria-current="true"
                            className="block w-full cursor-pointer rounded-lg bg-white p-4 text-gray-dark hover:bg-primary-dark hover:text-white"
                        >
                            {item.title}
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
}