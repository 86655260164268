import { gql } from '@apollo/client';
import { BEST_TIMES_TO_CLIMB_FRAGMENT, DAILY_FORECAST_FRAGMENT, HOURLY_FORECAST_FRAGMENT, PAST_PRECIP_FRAGMENT } from '../forecast/fragments';
import { AREA_FRAGMENT, AREA_ID_NAME_FRAGMENT, CITY_FRAGMENT, LOCATION_FRAGMENT } from './fragments';

export const AREAS = gql`
    query {
        areas {
            ...areaFragment
        }
    }  
    ${AREA_FRAGMENT}
`;

export const STATE_AREAS = gql`
    query {
        areas(onlyStates: true) {
            ...areaFragment
        }
    }  
    ${AREA_FRAGMENT}
`;

export const AREAS_BY_ID = gql`
    query($ids: [ID!]!){
        areasById(ids: $ids) {
            ...areaFragment
        }
    }  
    ${AREA_FRAGMENT}
`;

export const AREAS_ID_NAME = gql`
    query {
        areas {
            id
            name
            path
        }
    }
`;

export const AREAS_BY_NAME = gql`
    query($name: String!) {
        areasByName (name: $name){
            ...areaFragment
        }
    }
    ${AREA_FRAGMENT}
`;

export const AREAS_CITIES_BY_NAME = gql`
    query($name: String!, $includeCities: Boolean!) {
        areasCitiesByName (name: $name, includeCities: $includeCities){
            areas {
                ...areaFragment
            }
            cities {
                ...cityFragment
            }  
        }
    }
    ${AREA_FRAGMENT},
    ${CITY_FRAGMENT}
`;

export const AREAS_ID_NAME_BY_NAME = gql`
    query($name: String!) {
        areasByName (name: $name){
            ...areaIdNameFragment
        }
    }
    ${AREA_ID_NAME_FRAGMENT}
`;

export const AREAS_ID_NAME_BY_IDS = gql`
     query($ids: [ID!]!){
        areasById(ids: $ids) {
            ...areaIdNameFragment
        }
    }
    ${AREA_ID_NAME_FRAGMENT}
`;

export const AREA_ID_NAME_BY_LAT_LONG = gql`
    query($latitude: String!, $longitude: String!) {
        areaByLatLong (latitude: $latitude, longitude: $longitude){
           ...areaIdNameFragment
        }
    }
    ${AREA_ID_NAME_FRAGMENT}
`;

export const AREA_BY_PATH = gql`
    query($path: String!) {
        areaByPath (path: $path){
            ...areaFragment
        }
    }
    ${AREA_FRAGMENT}
`;




export const AREA = gql`
    query($id: String!) {
        area (id: $id){
            ...areaFragment
        }
    }
    ${AREA_FRAGMENT}
`;

export const AREA_HOURLY_FORECAST = gql`
    query($id: String!) {
        area (id: $id){
            ...areaFragment
            ...hourlyForecastFragment
        }
    }
    ${AREA_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
`;

export const AREA_DAILY_FORECAST = gql`
    query($id: String!) {
        area (id: $id){
            ...areaFragment
            dailyForecast {
                ...dailyForecastFragment
            }
        }
    }
    ${AREA_FRAGMENT}
    ${DAILY_FORECAST_FRAGMENT}
`;

export const AREA_DAILY_HOURLY_CURRENT_FORECAST = gql`
    query($id: String!, $currentLocationPayload: CurrentLocationPayload, $daytimeOnly: Boolean, $weatherModel: String, $pastPrecipHours: Float, $temperaturePreferences: IdealMinMaxInput, $metricUnits: Boolean, $sunShade: Float ) {
        area (id: $id, currentLocationPayload: $currentLocationPayload){
            ...areaFragment
             forecasts(daytimeOnly: $daytimeOnly, weatherModel: $weatherModel, pastPrecipHours: $pastPrecipHours, temperaturePreferences: $temperaturePreferences, metricUnits: $metricUnits, sunShade: $sunShade) {
                hourlyForecast {
                    ...hourlyForecastFragment
                }
                dailyForecast {
                    ...dailyForecastFragment
                }
                currentWeather {
                    ...hourlyForecastFragment
                }
                ...bestTimesToClimbFragment
                ...pastPrecipFragment
             }
           
        }
    }
    ${AREA_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
    ${DAILY_FORECAST_FRAGMENT}
    ${BEST_TIMES_TO_CLIMB_FRAGMENT}
    ${PAST_PRECIP_FRAGMENT}

`;

export const CITY_DAILY_HOURLY_CURRENT_FORECAST = gql`
    query($stateCode: String!, $countryCode: String!, $latitude: String!, $longitude: String!, $daytimeOnly: Boolean, $weatherModel: String, $pastPrecipHours: Float, $temperaturePreferences: IdealMinMaxInput, $metricUnits: Boolean, $sunShade: Float ) {
        city(stateCode: $stateCode, countryCode: $countryCode, latitude: $latitude, longitude: $longitude){
            ...cityFragment
             forecasts(daytimeOnly: $daytimeOnly, weatherModel: $weatherModel, pastPrecipHours: $pastPrecipHours, temperaturePreferences: $temperaturePreferences, metricUnits: $metricUnits, sunShade: $sunShade) {
                hourlyForecast {
                    ...hourlyForecastFragment
                }
                dailyForecast {
                    ...dailyForecastFragment
                }
                currentWeather {
                    ...hourlyForecastFragment
                }
                ...bestTimesToClimbFragment
                ...pastPrecipFragment
             }
           
        }
    }
    ${CITY_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
    ${DAILY_FORECAST_FRAGMENT}
    ${BEST_TIMES_TO_CLIMB_FRAGMENT}
    ${PAST_PRECIP_FRAGMENT}

`;

export const LOCATION_DAILY_HOURLY_CURRENT_FORECAST = gql`
    query($name: String!, $latitude: String!, $longitude: String!, $daytimeOnly: Boolean, $weatherModel: String, $pastPrecipHours: Float, $temperaturePreferences: IdealMinMaxInput, $metricUnits: Boolean, $sunShade: Float ) {
        location(name: $name, latitude: $latitude, longitude: $longitude){
            ...locationFragment
             forecasts(daytimeOnly: $daytimeOnly, weatherModel: $weatherModel, pastPrecipHours: $pastPrecipHours, temperaturePreferences: $temperaturePreferences, metricUnits: $metricUnits, sunShade: $sunShade) {
                hourlyForecast {
                    ...hourlyForecastFragment
                }
                dailyForecast {
                    ...dailyForecastFragment
                }
                currentWeather {
                    ...hourlyForecastFragment
                }
                ...bestTimesToClimbFragment
                ...pastPrecipFragment
             }
           
        }
    }
    ${LOCATION_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
    ${DAILY_FORECAST_FRAGMENT}
    ${BEST_TIMES_TO_CLIMB_FRAGMENT}
    ${PAST_PRECIP_FRAGMENT}

`;

export const AREAS_DAILY_HOURLY_CURRENT_FORECASTS = gql`
    query($ids: [ID!]!, $daytimeOnly: Boolean, $weatherModel: String, $pastPrecipHours: Float, $temperaturePreferences: IdealMinMaxInput, $metricUnits: Boolean, $sunShades: [Float!]!) {
        areasById (ids: $ids, sunShades: $sunShades){
            ...areaFragment
             forecasts (daytimeOnly: $daytimeOnly, weatherModel: $weatherModel, pastPrecipHours: $pastPrecipHours, temperaturePreferences: $temperaturePreferences, metricUnits: $metricUnits) {
                hourlyForecast {
                    ...hourlyForecastFragment
                }
                dailyForecast {
                    ...dailyForecastFragment
                }
                currentWeather {
                    ...hourlyForecastFragment
                }
                ...bestTimesToClimbFragment
                ...pastPrecipFragment
             }
           
        }
    }
    ${AREA_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
    ${DAILY_FORECAST_FRAGMENT}
    ${BEST_TIMES_TO_CLIMB_FRAGMENT}
    ${PAST_PRECIP_FRAGMENT}
`;

export const AREAS_QUICK_LOOK_FORECASTS = gql`
    query($ids: [ID!]!, $daytimeOnly: Boolean, $weatherModel: String, $pastPrecipHours: Float, $temperaturePreferences: IdealMinMaxInput, $metricUnits: Boolean, $sunShades: [Float!]!) {
        areasById (ids: $ids, sunShades: $sunShades){
            ...areaFragment
             forecasts (daytimeOnly: $daytimeOnly, weatherModel: $weatherModel, pastPrecipHours: $pastPrecipHours, temperaturePreferences: $temperaturePreferences, metricUnits: $metricUnits) {
               hourlyForecast {
                    ...hourlyForecastFragment
                }
             }   
        }
    }
    ${AREA_FRAGMENT}
    ${HOURLY_FORECAST_FRAGMENT}
`;