
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useRecoilState } from 'recoil';
import { compareAreasState } from '../../state/compareAreasState';

type ActionsProps = {
    children: ReactNode,
    className?: string,
    size?: number,
    icon?: React.ReactNode,
    allowHover?: boolean;
    alignment?: string;
}

  
const ActionsButton = ({ children, className, size = 25, icon=<BsThreeDotsVertical size={size}/>, allowHover=false, alignment='right-[16px] top-[16px]' }: ActionsProps) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [showActions, setShowActions] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
    const [compareAreas] = useRecoilState(compareAreasState);
    const [numberOfAreas, setNumberOfAreas] = useState(0);
    
    useEffect(() => {
        
        if (numberOfAreas !== compareAreas.length) {
            setShowActions(false);
        }
        setNumberOfAreas(compareAreas.length);
        
    }, [compareAreas]);

    useEffect(() => {

        const handleDocumentClick = () => {
            // Always close the menu if a click is detected anywhere on the document
            setShowActions(false);
        };

        // Attach event listener to close menu on click anywhere in the document
        document.addEventListener('click', handleDocumentClick);

        return () => {
            // Remove event listener when component is unmounted
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [allowHover]);

    const handleMouseEnter = () => {
        setHoverTimeout(setTimeout(() => {
            setShowActions(true);
        }, 300));
    };

    const handleMouseLeave = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setShowActions(false);
    };
  
    const toggleActions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!allowHover) {
            setShowActions(!showActions);
            e.stopPropagation();
        }
    };
  
    return (
        <div ref={menuRef} onMouseEnter={allowHover ? handleMouseEnter : undefined} onMouseLeave={allowHover ? handleMouseLeave : undefined}>
            <button className={`${className} ${alignment}`} onClick={(e) => toggleActions(e)}>
                {icon}
            </button>
  
            {showActions && (
                <div className={`absolute ${alignment} z-10 mt-7 w-40 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-light`}>
                    <div className="py-1" role="none">
                        {React.Children.map(children, (child, index) => (
                            <div key={index} className='hover:bg-primary hover:text-white'>
                                {child}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
  
export default ActionsButton;