
export default function FeatureRequest() {
    document.title = 'Feature Request';
    return (
        <div className="flex justify-center w-full h-screen">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf5VV7Qlqu0KcNL0P9ZAf0qjo1gujSANEsaiZdqYBhglK9JAw/viewform?embedded=true" className="w-full h-full">Loading…</iframe>
        </div>
    );
}



