import { useNavigate } from 'react-router-dom';
import HomePageHeader from '../images/Vida.png';
import ClimberClipping from '../images/ClimberClipping.jpeg';
import LogoWhite from '../images/logoWhite.png';
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import Waco from '../images/waco.jpeg';
import Kyle from '../images/KyleHome.jpg';
import Organic from '../images/Organic.png';
import Whip from '../images/whip.jpg';
import AreasComboboxComponent from '../components/areas/AreasCombobox';
import StarsComponent from '../components/common/Stars';
import { AreaIdName } from '../types/Area';
import FAQ from '../components/home/FAQ';
import { useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import OpenBetaIcon from '../images/OpenBetaIcon.png';
import OpenMeteoIcon from '../images/OpenMeteo.png';
import Rob from '../images/Rob.jpg';
import { createUrlSubdomain, isAndroidApp, isIosApp } from '../utils/CommonUtils';
import { City } from '../types/City';
import CompareAreasQuickLook from '../components/compare/CompareAreasQuickLook';
import { Helmet } from 'react-helmet';

const faqs = [
    {
        question: 'What is the Climbit Score and how is it calculated?',
        answer: 
            <div>
                <p>
                The Climbit Score algorithm is designed to provide climbers with precise and relevant weather forecasts tailored to specific climbing locations and personal preferences. A simple zero to five-star rating is calculated based on five key weather variables: temperature, current and past precipitation, humidity, wind speed, and cloud cover. The user preferences menu (top right of the page) allows you to set the hours of past precipitation that should be considered when calculating the Climbit Score (remember to wait up to 48 hours before climbing on sandstone!). The algorithm also automatically adjusts cloud cover calculations before sunrise and after sunset.
                </p>
                <div className='flex flex-col space-y-6 mt-4'>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={0} max={5} size={30}/>
                        <p>
                        You'd rather be training or climbing in the gym than be outside in this mess.
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={1} max={5} size={30}/>
                        <p>
                        Meh, it's doable, but you're constantly battling against the elements and wishing for better conditions.
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={2} max={5} size={30}/>
                        <p>
                        It's not bad, but it's not great either. Don't expect any first ascents today.
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={3} max={5} size={30}/>
                        <p>
                        The weather's cooperating, climbing feels smooth, and you're making progress on your project.
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={4} max={5} size={30}/>
                        <div className="flex items-start">   
                            <p>
                        The conditions are dialed in and you're crushing it. The friction is starting to make you wonder if you could fall off even if you tried.
                            </p>                         
                        </div>
                        
                    </div>
                    <div className='flex flex-row items-center space-x-2 font-semibold'>
                        <StarsComponent value={5} max={5} size={30}/>
                        <p>
                        All. Time. Conditions. Quit your job, sell your house, buy a van, and go send your project!                         </p>
                    </div>
                </div>
            </div>
    },
    {
        question: 'How is previous precipitation data incorporated into the Climbit Score?',
        answer:
            <div className='pl-4'>
                We incorporate previous precipitation probabilities and accumulation to adjust the Climbit Score. By default, we include 12 hours of past precipitation data from the forecasted time point. For instance, if you're viewing the Climbit Score for 11 am on Saturday, it will account for rain starting from 11 pm on Friday. This principle applies to both past precipitation and projected precipitation leading up to a forecast in the future. You have the control to adjust the duration of time considered under user preferences.
            </div>
    },
    {
        question: 'How does Climbit calculate the "feels like" temperature?',
        answer:
            <div className='pl-4'>
                Climbit utilizes a proprietary calculation to estimate the "feels like" temperature specifically tailored to climbing conditions. The calculation takes into account temperature, humidity, wind speed, solar radiation, and UV index. By combining these elements, Climbit provides you with a more accurate representation of how the weather conditions will feel while you are actively climbing. 
            </div>
    },
    {
        question: 'How does the sun/shade slider affect the Climbit score?',
        answer:
        <div className='pl-4'>
            <p>The sun/shade slider allows you to customize your Climbit Score based on how much sun or shade a climbing area gets. Your choice on the slider will dynamically adjust the Climbit Score by incorporating a more precise “feels like" temperature for your climbing destination. This selection will stay consistent for each specific location, carrying over to the compare page and remaining cached in your browser history for future visits.</p>
            <br/>
            <p>Here's how each option works:</p>
            <ul className='list-disc mx-5'>
                <li>
                    <strong>All Day Sun:</strong> Full sun exposure. "Feels like" temperature includes solar radiation and UV index.
                </li>
                <li>
                    <strong>AM Shade:</strong> Shade until noon. Morning solar radiation and UV index are not included in the "feels like" temperature.
                </li>
                <li>
                    <strong>PM Shade:</strong> Shade after noon. Afternoon solar radiation and UV index are not included in the "feels like" temperature.
                </li>
                <li>
                    <strong>All Day Shade:</strong> No direct sun. Solar radiation and UV index are excluded from the "feels like" temperature all day.
                </li>
            </ul>
            <br/>
            <p>In general, the following selections should be made depending on your knowledge of the orientation of the specific location:</p>
            <ul className='list-disc mx-5'>
                <li>
                Choose “All Day Sun" for South-facing crags.
                </li>
                <li>
                Choose "AM Shade" for West-facing crags.
                </li>
                <li>
                Choose "PM Shade" for East-facing crags
                </li>
                <li>
                Choose "All Day Shade" for North-facing crags
                </li>
            </ul>
        </div>
    },
    {
        question: 'Where do you source your weather data?',
        answer:
            <div className='pl-4'>
                Climbit sources weather forecasts <a href="https://open-meteo.com/" target='blank_' className="text-primary no-underline hover:underline">Open-Meteo</a>, combining advanced local (1 km resolution) and global (11 km) models. The models include reputable national services like DWD, NOAA, Meteofrance, and CMC, ensuring highly accurate global forecasts. Detailed predictions for the first 2-3 days utilize 1 km models, seamlessly transitioning to 11 km global models for the rest of the 7-day forecast, with hourly updates for current and precise information. 
            </div>
    },
    {
        question: 'How precise are Climbit\'s weather forecasts?',
        answer: 
            <div className='pl-4 space-y-4'>
                <p>Since our weather sources become more precise as your climbing date approaches, we recommend monitoring the weather pattern 7 days before your trip, initiating detailed trip planning and clearing your schedule around 4 days prior, and confirming your plans 2 days before your adventure.</p>
                <p>We employ hyper-local models for forecasts within 0-3 days - Forecasts for your destinations are focused on a 1km x 1km area that includes the GPS coordinates sourced from OpenBeta. For forecasts spanning 3-7 days, we utilize global models - Forecasts for your destination are focused on an 11km x 11km area that includes those same GPS coordinates.</p>
                <p>While other weather models are available in the User Preferences menu, our default choice is Open-Meteo’s Best Match model. This model aims to provide accurate and reliable weather forecasts by selecting the most suitable forecast data from various available weather models and blending them based on their performance and relevance to the specific location and time period. By combining strengths of multiple weather models, the Best Match model minimizes forecast errors and enhances overall forecast quality.</p>   
            </div>
    },
    {
        question: 'Does Climbit provide forecasts for outside the US?',
        answer:
            <div className='pl-4'>
                Climbit aims to expand its coverage in the future, but currently, we primarily focus on climbing locations in the United States.
            </div>
    },
];

const HomePage = () => {
    const navigate = useNavigate();
    document.title = 'Welcome to Climbit | Rock Climbing Weather Forecasts';
    const isIOSApp = isIosApp();
    const isAndroid = isAndroidApp();

    useEffect(() => {
        // Function to handle hash changes
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.getElementById(hash.substring(1));
                if (element && element.getBoundingClientRect()) {
                // Calculate the scroll position, adjusting for the fixed navigation bar
                    const scrollPosition = element.getBoundingClientRect().top + window.scrollY - 65;

                    // Scroll to the adjusted position
                    window.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    });
                }
            }
        };
    
        // Attach the event listener for hash changes
        window.addEventListener('hashchange', handleHashChange);
    
        // Call the function for the initial hash
        handleHashChange();
    
        // Cleanup: Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [window.location.hash]);

    return (
        <div className="min-h-screen flex flex-col">
            <Helmet>
                <title>Climbit | Rock Climbing Weather Forecasts </title>
            </Helmet>
            {/* Full-width image section */}
            <div className="relative h-[30rem] bg-cover bg-center">
                <img
                    src={HomePageHeader}
                    className="h-full object-cover w-full"
                    alt="image"
                />
                {/* Overlay for text */}
                <div className="absolute inset-0 bg-black opacity-30"></div>
    
                {/* Text centered on the left half */}
                <div className="absolute inset-0 flex justify-start items-center text-white text-left">
                    <div className="md:w-1/2 p-8">
                        <div className='flex flex-row items-center'>
                            <h1 className="text-5xl font-bold">
                            Climbit
                            </h1>
                            <img
                                src={LogoWhite}
                                className='w-[3rem] ml-4'
                                alt="logo"
                            />
                        </div>
                        <div className='flex flex-row'>
                            <TypeAnimation
                                sequence={[
                                    'Change the way you climb!',
                                    1000,
                                    'Change the way you adventure!',
                                    1000,
                                    'Change the way you plan!',
                                    1000,
                                    'Change the way you explore!',
                                    1000,
                                    'Change the way you project!',
                                    1000,
                                    'Change the way you send!',
                                    1000,
                                    'Change the way you prepare!',
                                    1000,
                                ]}
                                wrapper="span"
                                speed={20}
                                className='text-xl font-semibold block'
                                repeat={Infinity}
                            />
                        </div>
                    </div>
                </div>

                {/* Search bar at the bottom */}
                <div className="absolute bottom-0 md:w-[550px] w-[350px] left-1/2 transform -translate-x-1/2 mb-4 z-10" id='search-bar-homepage'>
                    <AreasComboboxComponent 
                        includeCities={true}
                        onFocus={() => {
                            const searchBar = document.getElementById('search-bar-homepage');
                            if (searchBar) {
                                setTimeout(() => {
                                    const searchBarTop = searchBar.getBoundingClientRect().top + window.scrollY - 65;
                                    window.scrollTo({
                                        top: searchBarTop,
                                        behavior: 'smooth',
                                    });
                                }, 400);
                            }
                        }}
                        onChange={(selectedAreaCity: AreaIdName | City) => {
                            if (selectedAreaCity && 'id' in selectedAreaCity ) {
                                navigate(`/area/${selectedAreaCity.id}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                window.scrollTo(0, 0);
                            } else if (selectedAreaCity && 'countryCode' in selectedAreaCity && 'stateCode' in selectedAreaCity && 'name' in selectedAreaCity) {
                                navigate(`/city/${selectedAreaCity.countryCode}/${selectedAreaCity.stateCode}/${selectedAreaCity.latitude}/${selectedAreaCity.longitude}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                window.scrollTo(0, 0);
                            }
                        }} placeholderText='Start typing to search locations' />
                </div>
            </div>

            <div className="flex-col-reverse md:flex md:flex-row items-center justify-center mt-4 mb-4">
                <div className="w-full md:w-[35%] p-6  md:order-1">
                    <h1 className="text-5xl font-bold mb-4 text-center">Compare Locations</h1>
                    <p className="text-lg text-gray mb-4"> Whether it's a local bouldering session or an epic road trip, Climbit's comparison page helps you quickly find the best weather conditions. Easily decide when and where to climb without the hassle of manual comparisons. Get a quick preview of our comparison tool here, then head to the Compare Page to explore the full feature.</p>
                    <div className='flex justify-center'>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                navigate('/compare');
                                window.scrollTo(0, 0);
                            }}
                        >
                        Go to Compare Page
                        </button>
                    </div> 
                </div>
                <div className="w-full md:w-[65%] p-2  md:order-2">
                    <CompareAreasQuickLook/>
                </div>
            </div>
             
    
            {/* Half-width image and text section */}
            <div className="flex-row md:flex items-center justify-center mt-4 mb-4">
                <div className="w-full md:w-1/2 p-6">
                    <img
                        src={Whip}
                        alt="Climber taking a fall"
                        className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                    />
                </div>
                <div className="w-full md:w-1/2 p-6">
                    <div className='flex md:flex-row flex-col items-center justify-center mb-2'>
                        <h1 className="text-5xl font-bold text-center">Climbit Score</h1>
                        <div className='ml-4 mt-2'>
                            <StarsComponent value={4.6} max={5}/>
                        </div>
                    </div>
                    <p className="text-lg text-gray mb-4">Elevate your climbing experience with our Climbit Score system, which helps identify the best climbing conditions. The Climbit Score consolidates hyper-local weather reports from Open-Meteo, incorporating essential metrics such as temperature, humidity, current and past precipitation, cloud cover, and wind speed to provide you with a simple zero to five-star rating of current and forecasted conditions.</p>  
                </div>
            </div>

            <div className="flex-row md:flex items-center justify-center mt-4 mb-4 ">
                <div className="w-full md:w-1/2 p-6 md:order-2">
                    <img
                        src={ClimberClipping}
                        alt="Climber Clipping"
                        className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                    />
                </div>
                <div className="w-full md:w-1/2 p-6 md:order-1">
                    <h1 className="text-5xl font-bold mb-4 text-center">Explore</h1>
                    <p className="text-lg text-gray mb-4">Discover a world of climbing possibilities with Climbit. Access climbing locations all across the country thanks to OpenBeta's climbing data. Venture into uncharted territory, plan your next session with friends, or pick the perfect time to crush your local project.</p>
                    <div className='flex justify-center'>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                navigate('/areas');
                                window.scrollTo(0, 0);
                            }}
                        >
                        Try it out!
                        </button>
                    </div>
                </div>
            </div>
            {isIOSApp === false && isAndroid === false && (
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center mt-4 mb-4">
                    <div className="w-full md:w-1/2 p-6">
                        <img
                            src={Waco}
                            alt="Climber about to clip"
                            className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                        />
                    </div>
                    <div className="w-full md:w-1/2 p-6">
                        <div className="flex-row md:flex items-center justify-center mt-4 mb-4">
                            <div className="flex items-center justify-center mb-4 flex-col">
                                <div className="flex flex-row mb-4">
                                    <h1 className="md:text-4xl text-2xl font-bold">Download The Climbit App!</h1>
                                </div>
                                <p className="text-lg text-gray mb-4 ">Climbit is now available on both the Apple App Store and Google Play Store. Click the buttons below to download now and start planning your next climbing adventure!</p>  
                                <div className='flex lg:flex-row lg:space-x-6 lg:space-y-0 space-x-0  flex-col space-y-4'>
                                    <button
                                        className="px-4 py-1 border-2 rounded-md button shadow-md h-full"
                                        onClick={() => {
                                            window.open('https://apps.apple.com/us/app/climbit-score/id6517296523', '_blank', 'noreferrer');
                                        }}
                                    >
                                        <div className='flex flex-row text-center items-center space-x-1'>
                                            <FaAppStoreIos size={30} /> 
                                            <p>Apple App Store!</p>
                                        </div>
                                    </button>
                                    <button
                                        className="px-4 py-1 border-2 rounded-md button shadow-md h-full"
                                        onClick={() => {
                                            window.open('https://play.google.com/store/apps/details?id=climbitscore.app', '_blank', 'noreferrer');
                                        }}
                                    >
                                        <div className='flex flex-row text-center items-center space-x-1'>
                                            <FaGooglePlay size={30} /> 
                                            <p>Google Play Store!</p>
                                        </div>
                                    </button>
                                </div>       
                            </div>  
                        </div>
                    </div>
                </div>
            )} 
            <div className='py-24'>
                <h1 className='text-center w-full text-5xl font-bold mb-4' id='faq'>FAQs</h1>
                <FAQ faqs={faqs} />     
            </div>   
            <h1 className='text-center w-full text-5xl font-bold mt-6 mb-4' id='about'>About Us</h1>
            <h3 className='text-center w-full text-xl font-bold mt-2'>Welcome to Climbit - Customized hyper-local weather forecasts to help you decide exactly when and where to climb.</h3>     

            <div className="flex-row md:flex items-center justify-center mt-4 mb-4">
                <div className="w-full md:w-1/2 p-6">
                    <img
                        src={Organic}
                        alt="Organic climber"
                        className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                    />
                </div>
                <div className="w-full md:w-1/2 p-6">
                    <p className="text-lg text-gray mb-4">Since we started building and tinkering with spreadsheets to simplify generic and overly complicated weather forecasts back in 2019, Climbit’s way of analyzing weather data has completely changed the way our climbing crew plans adventures and tackles our projects. Now, we’re bringing this powerful tool to you - climbers who map out their climbing trips and game plan their projecting sessions. Climbit empowers you to fully customize parameters in our rating system to ensure enjoyable conditions and faster sends.</p>  
                </div>
            </div> 
            <div className="flex-col-reverse md:flex md:flex-row items-center justify-center mt-4 mb-4">
                <div className="w-full md:w-1/2 p-6  md:order-2">
                    <img
                        src={Kyle}
                        alt="Kyle in thought climbing"
                        className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                    />
                </div>
                <div className="w-full md:w-1/2 p-6  md:order-1">
                    <p className='text-lg text-gray mb-4'>Climbit’s founders have wide experience climbing all styles, all over the country from bouldering in the desert, multi-pitch trad in the mountains, run out slabs in the south and clipping bolts at the Red. We love it all!</p>
                </div>
            </div>
            <div className="flex-row md:flex items-center justify-center mt-4 mb-4">
                <div className="w-full md:w-1/2 p-6">
                    <img
                        src={Rob}
                        alt="Rob"
                        className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                    />
                </div>
                <div className="w-full md:w-1/2 p-6">
                    <h1 className="text-4xl font-bold mb-4 text-center">Photography by Robert Liberatore</h1>
                    <p className="text-lg text-gray mb-4">Robert Liberatore is a New York based photographer, director, DP and drone pilot with a love for climbing and exploring the outdoors. His experience as a former engineer provides a unique perspective on the creative process that allows him to prioritize efficiency and versatility while shooting on location for clients. When he's not behind the lens, Robert enjoys plugging gear at the Gunks and preparing for his annual sufferfest at Horseshoe Hell.</p> 
                    <div className='flex flex-row justify-center md:space-x-8 space-x-4'>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('http://www.Libtography.com', '_blank', 'noreferrer');
                            }}
                        >
                            Website
                        </button>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('https://www.instagram.com/robert_lib/', '_blank', 'noreferrer');
                            }}
                        >
                            Instagram
                        </button>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('mailto:Robert@Libtography.com', '_blank', 'noreferrer');
                            }}
                        >
                            Email
                        </button>

                    </div> 
                </div>
            </div> 
            <h1 className='text-center w-full text-5xl font-bold mt-6 mb-4' id='powered'>Powered By</h1>
            <h3 className='text-center w-full text-xl font-bold mt-2 px-2'>Climbit utilizes open source projects in order to provide the most accurate and precise location and weather data!</h3> 
            <div className="flex-row md:flex items-center justify-top mt-4 mb-4">
                <div className="w-full md:w-1/2 p-6">
                    <div className="flex items-center justify-center">
                        <img src={OpenMeteoIcon} alt="OpenMeteo Logo" className="w-14 h-14 mr-2 mb-2"/>
                        <h1 className="md:text-5xl text-4xl font-bold mb-4 text-center">Open-Meteo</h1>
                    </div>
                    <p className='text-lg text-gray mb-4 px-4'>
                    Open-Meteo is a platform that delivers weather forecast data known for its accuracy and reliability. They provide highly precise global forecasts, offering detailed predictions for the next 2-3 days using 1 km models and transitioning seamlessly to 11 km global models for the remaining 7-day forecast. With hourly updates, Open-Meteo offers current and precise weather information, facilitating Climbit's ability to simplify your decision-making process.                    </p>
                    <div className='flex flex-row justify-center space-x-4'>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('https://open-meteo.com/', '_blank', 'noreferrer');
                            }}
                        >
                            Learn More
                        </button>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('https://open-meteo.com/en/license/', '_blank', 'noreferrer');
                            }}
                        >
                            License
                        </button>
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-6">
                    <div className="flex items-center justify-center mb-4">
                        <img src={OpenBetaIcon} alt="OpenBeta Logo" className="w-10 h-10 mr-2"/>
                        <h1 className="md:text-5xl text-4xl font-bold">OpenBeta</h1>
                    </div>                    
                    <p className='text-lg text-gray mb-4 px-4'>
                    By collaborating with local climbing communities, guidebook authors, and outdoor organizations, OpenBeta compiles comprehensive data on climbing routes, grades, access points, and current conditions. Leveraging this wealth of information enables Climbit to offer you precise details about specific climbing locations to facilitate informed decision-making, foster community engagement, and enhance your overall climbing experience.                    </p>
                    <div className='flex flex-row justify-center space-x-4'>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('https://docs.openbeta.io', '_blank', 'noreferrer');
                            }}
                        >
                            Learn More
                        </button>
                        <button
                            className="px-4 py-1 border-2 rounded-md button shadow-md"
                            onClick={() => {
                                window.open('https://docs.openbeta.io/faq#license', '_blank', 'noreferrer');
                            }}
                        >
                            License
                        </button>
                    </div>
                </div>
               
            </div>
            
        </div>
    );
};
export default HomePage;