import { PastPrecip } from '../../types/Forecast';
import { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { getDisplayPrecipitation } from '../../utils/WeatherUtils';
import { BsDroplet } from 'react-icons/bs';
import { RiCheckboxCircleLine, RiErrorWarningLine } from 'react-icons/ri';
import { useRecoilValue } from 'recoil';
import { precipUnitSelector, unitPreferencesState } from '../../state/unitPreferencesState';

type PastPrecipProps = {
    pastPrecip: PastPrecip
  }

export default function PastPrecipComponent({pastPrecip}: PastPrecipProps) {
    const {metricUnits} = useRecoilValue(unitPreferencesState);
    const { last24, last48, lastPrecip } = pastPrecip;
    const last24Display = last24 ? getDisplayPrecipitation(last24, metricUnits) : 0;
    const last48Display = last48 ? getDisplayPrecipitation(last48, metricUnits) : 0;
    const mostRecentAmountDisplay = lastPrecip?.amount ? getDisplayPrecipitation(lastPrecip.amount, metricUnits) : 0;
    const [isHovered, setIsHovered] = useState(false);
    const precipUnit = useRecoilValue(precipUnitSelector);


    const [showDetails, setShowDetails] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowDetails(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDetails = () => {
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    const WarningPrecip = () => (
        <div style={{ position: 'relative', display: 'inline-block' }} className='mr-1'>
            <BsDroplet size={24}/>
            <div style={{ position: 'absolute', top: '70%', left: '93%', transform: 'translate(-50%, -50%)', backgroundColor: isHovered ? '#43A9CB' : last24 !== undefined && last24 > 0 ?  '#ef4d4d' : '#ffb500', width: 'fit-content', borderRadius: '60%' }}>                
                <RiErrorWarningLine size={18}/>
            </div>
        </div>
    );

    const NoPrecip = () => (
        <div style={{ position: 'relative', display: 'inline-block' }} className='mr-1'>
            <BsDroplet size={24} />
            <div style={{ position: 'absolute', top: '70%', left: '93%', transform: 'translate(-50%, -50%)', backgroundColor: isHovered ? '#43A9CB' : '#7BB662', borderRadius: '50%' }}>                
                <RiCheckboxCircleLine size={18}/>
            </div>
        </div>
    );

    return (
        <div ref={ref} className="relative">
            <button 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className='md:px-4 px-3 py-1 border-2 rounded-md shadow-md border-primary hover:bg-primary' onClick={toggleDetails}>
                {last48 !== undefined && last48 > 0 && (
                    <div className='flex flex-row space-x-0'>
                        {WarningPrecip()}
                    </div>
                        
                )}
                {!last24 && !last48 && (
                    <div className='flex flex-row space-x-0'>
                        {NoPrecip()}
                    </div>
                )}  
            </button>      

            {showDetails && (
                <div className="z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-full h-full bg-black bg-opacity-50" onClick={toggleDetails}>
                    <div className="bg-white shadow-md p-4 rounded-md relative border-2 border-primary text-center w-4/5 md:w-[30rem]" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-0 right-0 p-2" onClick={toggleDetails}>
                            <MdClose size={24} />
                        </button>
                        <h2 className="text-xl font-bold text-black mb-4">Past Precipitation Totals</h2>
                        <div className='flex flex-row space-x-2 justify-center'>
                            <p className='text-lg'>24 hours:</p>
                            <p className='text-lg font-semibold'>{last24Display}{precipUnit}</p>
                        </div>
                        <div className='flex flex-row space-x-2 justify-center'>
                            <p className='text-lg'>48 hours:</p>
                            <p className='text-lg font-semibold'>{last48Display}{precipUnit}</p>
                        </div>
                        {pastPrecip.lastPrecip !== undefined && (
                            <>      
                                {pastPrecip.lastPrecip.amount !== undefined && pastPrecip.lastPrecip.amount > 0 && (
                                    <div className='flex flex-col md:space-x-2 justify-center py-4'>
                                        <p className='text-lg'>Most recent</p>
                                        <p className='text-lg font-semibold'>{pastPrecip.lastPrecip?.time ? ` ${pastPrecip.lastPrecip?.time} (${mostRecentAmountDisplay}${precipUnit})` : 'None in the past 48 hours'}</p>
                                    </div>
                                )}
                                {pastPrecip.lastPrecip.amount !== undefined && pastPrecip.lastPrecip.amount === 0  && (
                                    <div className='flex flex-row space-x-2 justify-center py-4'>
                                        <p className='text-lg font-semibold'>No observed precipitation the past 48 hours!</p>
                                    </div>
                                )}
                            </>           
                        )}
                        <div>
                            <p className='text-xs'>
                            Precipitation data is based on forecasts. Climbers should check actual rock conditions, especially with brittle rock types like sandstone. Always prioritize safety and help preserve climbing areas!
                            </p>
                        </div>    
                    </div>
                </div>
            )} 
        </div>
       
    );
}