import { useRecoilValue } from 'recoil';
import { HourlyForecast} from '../../types/Forecast';
import { LineChartData } from '../../types/LineChart';
import { _getCompareLineData, createUniqueLabels, lineColors, sortDisplayDates } from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';
import { speedUnitSelector } from '../../state/unitPreferencesState';
import { getSpeedUnits } from '../../utils/WeatherUtils';

type WindSpeedChartProps = {
    hourlyForecasts: HourlyForecast[];
    selectedArea?: string;
    weeklyHourlyForecast?: HourlyForecast[][];
    showTitle?: boolean;
    showWeeklyForecast: boolean;
    color?: string;
    height?: string;
    shadow?: boolean;
    padding?: string;
    isMetric?: boolean;
    yMax?: number;
};

const WindSpeedChart = ({ hourlyForecasts, selectedArea, weeklyHourlyForecast, showWeeklyForecast, color, showTitle=true, height='h-[15rem]', shadow=true, padding='py-2 md:px-4 px-2', isMetric, yMax }: WindSpeedChartProps) => {    
    const uniqueLabels: string[] = createUniqueLabels(hourlyForecasts, 'displayTime');
    const repeatingLabels: string[] = weeklyHourlyForecast ? weeklyHourlyForecast[0]?.map(({displayTime}) => displayTime): [];
    const labels:string[] = showWeeklyForecast ? repeatingLabels : sortDisplayDates(uniqueLabels);
    const subLabels = showWeeklyForecast ? Array.from(new Set(weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate))) : null;
    const speedUnit = isMetric !== undefined ? getSpeedUnits(isMetric) : useRecoilValue(speedUnitSelector);

    const lineData: LineChartData[] = showWeeklyForecast ? _getCompareLineData(weeklyHourlyForecast?.flat(), 'climbit', 'climbitStars', selectedArea, 'displayTime', undefined, color)
        : _getCompareLineData(hourlyForecasts, 'climbit', 'climbitStars', selectedArea, 'displayTime', labels, color);
        
    return (
        <>
            <div className={`${padding} flex flex-col items-center justify-evenly w-full min-${height}`}>
                <div className="flex w-full">
                    {lineData  && labels && (
                        <LineChartCard title={`Wind Speed (${speedUnit})`} key={'hourlyForecast_6'}
                            labels={labels}
                            lineData={[
                                {
                                    color: lineColors.windSpeedLineColor, 
                                    labelDescription: 'Wind Speed', 
                                    values: showWeeklyForecast && weeklyHourlyForecast
                                        ? weeklyHourlyForecast.flat().map(({ windSpeed }) => windSpeed)
                                        : hourlyForecasts.map(({ windSpeed }) => windSpeed)
                                }
                            ]}
                            yMin={0}
                            yMax={yMax !== -Infinity ? yMax : undefined}
                            maintainAspectRatio={false}
                            height={height}
                            subLabels={subLabels}
                            labelSuffix={speedUnit}
                            showTitle={showTitle}
                            shadow={shadow}
                        />
                    )} 
                </div>
            </div>
        </>
    );
};

export default WindSpeedChart;
