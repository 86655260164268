import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { defaultUserPreferences } from './resetPreferencesState';

const { persistAtom } = recoilPersist();

export const weatherModelState = atom<string>({
    key: 'weatherModelState',
    default: defaultUserPreferences.weatherModel,
    effects_UNSTABLE: [persistAtom]
});
