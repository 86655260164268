import { GiRoundStar } from 'react-icons/gi';
import Tooltip from './Tooltip';

type StarsProps = {
    value: number,
    max: number,
    size?: number,
    tooltipPosition?: string
  }

export default function StarsComponent({value, max, size=40, tooltipPosition}: StarsProps) {
    const starsPercentage = Math.round((value / max) * 100);
    return (
        <Tooltip text={`Climbit Score: ${value.toString()}`} position={tooltipPosition} className='whitespace-nowrap'>
            <div className='stars-container' key='stars'>
            
                {Array.from(Array(max).keys()).map((_, i) => (
                    <div key={i}>
                        <GiRoundStar key={i} size={size} className='stars-star'/>
                    </div>
                
                
                ))}

                <div style={{ width: `${100 - starsPercentage}%` }} className='stars-overlay'/>
            </div>
        </Tooltip>
       
    );
}