/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { _getCompareLineData, createUniqueLabels, sortDisplayDates } from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';
import { HourlyForecast } from '../../types/Forecast';
import { useRecoilValue } from 'recoil';
import { precipUnitSelector, speedUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';

type HourlyForecastsCompareProps = {
    forecasts: HourlyForecast[],
    weeklyHourlyForecast: HourlyForecast[][],
    showWeeklyForecast: boolean,
}

const HourlyForecastsCompare = ({ forecasts, weeklyHourlyForecast, showWeeklyForecast }: HourlyForecastsCompareProps) => {
    const uniqueLabels: string[] = createUniqueLabels(forecasts, 'displayTime');
    const repeatingLabels: string[] = weeklyHourlyForecast[0]?.map(({displayTime}) => displayTime);
    const labels: string[] = showWeeklyForecast ? repeatingLabels : sortDisplayDates(uniqueLabels);
    const subLabels = showWeeklyForecast ? Array.from(new Set(weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate))) : null;
    const areas = showWeeklyForecast ? weeklyHourlyForecast.flat() : forecasts;
    const timeValues = showWeeklyForecast ? undefined : labels;
    const temperatureUnit = useRecoilValue(temperatureUnitSelector);
    const precipUnit = useRecoilValue(precipUnitSelector);
    const speedUnit = useRecoilValue(speedUnitSelector);


    return (
        <div>
            <div>
                {forecasts.length && (
                    <div className="pt-4 md:mx-5 mx-2">
                        <div className="flex flex-col items-center justify-evenly">
                            <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title="Temperature" key={'hourlyForecast_2'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'temperature',  undefined, undefined, 'displayTime', timeValues)}
                                    maintainAspectRatio={false}
                                    subLabels={subLabels} 
                                    labelSuffix={temperatureUnit}
                                />
                            </div>
                            <div className="flex w-full mi-h-[15rem]">
                                <LineChartCard title="Feels Like Temperature" key={'hourlyForecast_2'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'apparentTemperature', undefined, undefined, 'displayTime', timeValues)}
                                    maintainAspectRatio={false}
                                    subLabels={subLabels}
                                    labelSuffix={temperatureUnit}
                                />
                            </div>
                            <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title="Relative Humidity" key={'hourlyForecast_3'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'relativeHumidity', undefined, undefined, 'displayTime', timeValues)}
                                    yMin={0} yMax={100}
                                    maintainAspectRatio={false}
                                    subLabels={subLabels}
                                    labelSuffix='%'
                                />
                            </div>
                            <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title="Precipitation Amount" key={'hourlyForecast_4'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'quantitativePrecipitation',  undefined, undefined, 'displayTime', timeValues)}
                                    maintainAspectRatio={false}
                                    yMin={0}
                                    subLabels={subLabels}
                                    labelSuffix={precipUnit}
                                />

                            </div>
                            {forecasts[0] && forecasts[0].precipitationProbability !== null && ( <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title="Precipitation Probability" key={'hourlyForecast_6'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'precipitationProbability', undefined, undefined, 'displayTime', timeValues)}
                                    maintainAspectRatio={false}
                                    yMin={0} yMax={100}
                                    subLabels={subLabels}
                                    labelSuffix='%'
                                />
                            </div>)}
                            <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title="Cloud Cover" key={'hourlyForecast_5'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'cloudCover', undefined, undefined, 'displayTime', timeValues)}
                                    yMin={0} yMax={100}
                                    maintainAspectRatio={false}
                                    subLabels={subLabels}
                                    labelSuffix='%'
                                />
                            </div>

                            <div className="flex w-full min-h-[15rem]">
                                <LineChartCard title={`Wind Speed (${speedUnit})`} key={'hourlyForecast_6'} height="h-[15rem]"
                                    labels={labels}
                                    lineData={_getCompareLineData(areas, 'windSpeed', undefined, undefined, 'displayTime', timeValues)}
                                    yMin={0}
                                    maintainAspectRatio={false}
                                    subLabels={subLabels}
                                    labelSuffix={speedUnit}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HourlyForecastsCompare;