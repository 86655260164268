import toast from 'react-hot-toast';
import Tooltip from '../common/Tooltip';
import { MdOutlineIosShare } from 'react-icons/md';

type ShareButtonProps = {
        title: string,
        text?: string,
        url: string
}
    
export default function ShareButton({title, text, url}: ShareButtonProps) {
    return (
        <Tooltip text='Share' className='text-sm text-center' position='centered mt-1'>
            <button
                className="md:px-4 px-3 py-1 border-2 rounded-md button shadow-md"
                onClick={() => {
                    handleShare(title, url, text);
                }}
            >
                <span className="hidden md:block">Share</span>
                <MdOutlineIosShare className="block md:hidden" size={25} />
            </button>
        </Tooltip>
    );
}

const handleShare = async ( title: string, url: string, text?: string ) => {
    const shareData = {
        title: title ?? undefined, // Title of the content being shared
        text,  // Additional text that describes the content
        url,   // URL to be shared
    };
  
    if (navigator.share) {
        try {
            await navigator.share(shareData);
            toast.success('Link shared successfully!', {
                duration: 3500,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            });
        } catch (error: any) {
            console.log(`Error sharing link: ${error}`);
        }
    } else {
        try {
            // Copy the URL to the clipboard
            await navigator.clipboard.writeText(url);
            toast.success('Link copied to clipboard!', {
                duration: 3500,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            });
        } catch (error: any) {
            toast.error(`Error copying link: ${error}`, {
                duration: 3500,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            });
        }
    }
};
  
