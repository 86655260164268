
export default function BugReport() {
    document.title = 'Bug Report';
    return (
        <div className="flex justify-center w-full h-screen">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeVAbwGQPuYZeu0w-o9KXPAIH1QEEPpp5vBnXwNyMJ0zIjnQg/viewform?embedded=true" className="w-full h-full">Loading…</iframe>
        </div>
    );
}



