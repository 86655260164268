// Tooltip.tsx
import React, { useEffect, useState } from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  className?: string;
  position?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, className, position='-top-1 -mt-8' }) => {
    const [isHovered, setIsHovered] = useState(false);

    let timeoutId: NodeJS.Timeout;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isHovered) {
            timeoutId = setTimeout(() => {
                setIsHovered(false);
            }, 2200); // 2.2 seconds
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isHovered]);


    const handleMouseEnter = () => {
        timeoutId = setTimeout(() => {
            setIsHovered(true);
        }, 400); // Set the delay in milliseconds (adjust as needed).
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutId);
        setIsHovered(false);
    };

    return (
        <div className="relative inline-block">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>

            {isHovered && (
                <div 
                    className={`${className} absolute ${position} left-1/2 transform -translate-x-1/2 bg-black text-white p-2 rounded-md hidden md:inline z-40`}>
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;