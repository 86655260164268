/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HourlyForecast } from '../../types/Forecast';
import { weatherCodesMap } from './WeatherIcons';
import { FaAngleDown, FaAngleUp, FaHandHoldingWater } from 'react-icons/fa';
import { WiStrongWind, WiRain, WiThermometer, WiRaindrop, WiCloudy, WiDaySunny } from 'react-icons/wi';
import React from 'react';
import StarsComponent from '../common/Stars';
import { useRecoilValue } from 'recoil';
import { precipUnitSelector, speedUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';
import Tooltip from '../common/Tooltip';
import { getPrecipUnits, getSpeedUnits, getTemperatureUnits } from '../../utils/WeatherUtils';

type HourlyForecastTableRowProps = {
    hourlyForecast: HourlyForecast;
    isBestTimeToClimb?: boolean;
    tooltipPosition?: string;
    simplify?: boolean;
    currentWeather?: boolean;
    sunriseSunset?: React.ReactNode;
    isMetric?: boolean;
};

const HourlyForecastTableRow = ({ hourlyForecast, isBestTimeToClimb, tooltipPosition, simplify=false, currentWeather, sunriseSunset, isMetric }: HourlyForecastTableRowProps) => {
    const [showMoreDetail, setShowMoreDetail] = React.useState(false);
    const isDay = hourlyForecast.isDay;
    const isBestTimeToClimbClass = isBestTimeToClimb ? 'border-t-4 border-b-4 md:border-4 border-orange border-opacity-75' : `border-gray-light ${currentWeather ? 'border-2' :'border'}`;
    const isDayClass = isDay ? 'bg-white-secondary hover:bg-gray-light' : 'bg-white-dark hover:bg-white-dark-hover';
    const screenSizeClass = simplify ? 'hidden' : 'hidden lg:table-cell';
    const temperatureUnit = isMetric !== undefined ? getTemperatureUnits(isMetric) : useRecoilValue(temperatureUnitSelector);
    const speedUnit = isMetric !== undefined ? getSpeedUnits(isMetric) : useRecoilValue(speedUnitSelector);
    const precipUnit = isMetric !== undefined ? getPrecipUnits(isMetric) : useRecoilValue(precipUnitSelector);

    return (
        <>
            <tr className={`${isDayClass} ${isBestTimeToClimbClass} ${simplify ? 'min-w-[18rem]' : ''}} hover:cursor-pointer font-bold w-full flex items-center`} onClick={() => (setShowMoreDetail(!showMoreDetail))}>
                <td className={`font-bold text-gray pl-3 text-left whitespace-nowrap ${simplify ? 'w-[5rem]' : 'w-1/5 md:w-1/12'} `}>
                    <div className='flex flex-col items-center'>
                        {currentWeather ? 'Now' : hourlyForecast.displayTime}
                        {sunriseSunset && (
                            <>
                                {sunriseSunset}
                            </>

                        )}
                    </div>
                   
                </td>
                {hourlyForecast.climbit && hourlyForecast.climbit.climbitStars !== undefined && (
                    <td className="table-padding text-left flex-1">
                        <div className={`${simplify ? 'hidden' : 'hidden md:table-cell'}`}>
                            <StarsComponent value={hourlyForecast.climbit.climbitStars} max={5} size={30} tooltipPosition={tooltipPosition}/>
                        </div>
                        <div className={`icons-styling-hourly font-bold ${simplify ? '' : 'md:hidden' }`}>
                            <StarsComponent value={hourlyForecast.climbit.climbitStars} max={5} size={20} tooltipPosition={tooltipPosition}/>
                        </div>
                    </td>
                )}
                {hourlyForecast.temperature && (
                    
                    <td className="table-padding text-left flex-1">
                        <Tooltip text={`Feels Like: ${hourlyForecast.apparentTemperature.toFixed(1)}${temperatureUnit}`} position={tooltipPosition} className='whitespace-nowrap'>
                            <div className="icons-styling-hourly">
                                <WiThermometer size={20} className={screenSizeClass}/>
                                {hourlyForecast.temperature.toFixed(1)}{temperatureUnit}
                            </div>
                        </Tooltip>
                    </td>
                    
                )}

                {hourlyForecast.weatherCode !== undefined && (
                    <td className="table-padding text-left flex-1">
                        <Tooltip text={`Cloud Cover: ${hourlyForecast.cloudCover}%`} position={tooltipPosition} className='whitespace-nowrap'>
                            <div className="icons-styling-hourly">
                                <div className={simplify ? 'flex-shrink-0 whitespace-nowrap' : 'table-cell'}>
                                    {weatherCodesMap(hourlyForecast.isDay, 30).get(hourlyForecast.weatherCode.toString())}
                                </div>
                                <div className={`${simplify ? 'hidden' : 'hidden md:table-cell'}`}>
                                    {hourlyForecast.weatherCodeDescription}
                                </div>
                            </div>
                        </Tooltip>
                    </td>
                )}
                {hourlyForecast.precipitationProbability !== null && (<td className={`table-padding text-left flex-1 ${screenSizeClass}`}>
                    <Tooltip text={`${hourlyForecast.quantitativePrecipitation}${precipUnit}`} position={tooltipPosition} className='whitespace-nowrap'>
                        <div className="icons-styling-hourly">
                            <WiRain size={30} className={screenSizeClass}/>
                        Precip {hourlyForecast.precipitationProbability}%
                        </div>
                    </Tooltip>
                </td>)}
                <td className={`table-padding text-left flex-1 ${screenSizeClass}`}>
                    <div className="icons-styling-hourly">
                        <WiStrongWind size={20} className={`${screenSizeClass} mr-1`}/>
                        {hourlyForecast.windSpeed} {speedUnit} {hourlyForecast.windDirection}
                    </div>
                </td>
                <td className={`table-padding text-left flex-1 ${screenSizeClass}`}>
                    <div className="icons-styling-hourly">
                        <FaHandHoldingWater size={18} className={`${screenSizeClass} mr-1`}/>
                        Humidity &nbsp;{hourlyForecast.relativeHumidity}%
                    </div>
                </td>
                <td className="font-bold text-gray table-padding text-left flex-shrink-0">
                    <div className='icons-styling-hourly'>
                        {!showMoreDetail && (
                            <FaAngleDown size={18}/>
                        )}
                        {showMoreDetail && (
                            <FaAngleUp size={18}/>
                        )}
                    </div>
                </td>
            </tr>
            {showMoreDetail && (
                <tr className="bg-white border-b border-gray-light flex w-full" onClick={(event) => {event.stopPropagation(); setShowMoreDetail(!showMoreDetail);}}>
                    <td className='w-full'>
                        <div className={`${simplify ? 'p-2' : 'md:p-8 p-2'} w-full flex justify-center`}>
                            <table className={`${simplify ? 'w-full' : 'w-full md:w-3/5'} border-collapse border border-gray`}>
                                <tbody>
                                    <tr className={`${simplify ? '' : 'lg:hidden'} border border-gray-light`}>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    Wind
                                                </div>
                                                <div className='font-bold'>
                                                    {hourlyForecast.windSpeed} {speedUnit} {hourlyForecast.windDirection}
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    Humidity
                                                </div>
                                                <div className='font-bold'>
                                                    &nbsp;{hourlyForecast.relativeHumidity}%
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    Precipitation Chance
                                                </div>

                                                <div className='font-bold'>
                                                    {(hourlyForecast.precipitationProbability !== undefined && hourlyForecast.precipitationProbability !== null) ? `${hourlyForecast.precipitationProbability}%` : 'Not Available'}
                                                </div>                   
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border border-gray-light">
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiThermometer size={20} className={screenSizeClass}/>
                                                    <div>
                                                        Feels like
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {hourlyForecast.apparentTemperature}{temperatureUnit}
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiCloudy size={20} className={screenSizeClass}/>
                                                    <div>
                                                        Cloud Cover
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {hourlyForecast.cloudCover}%
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiRaindrop size={20} className={screenSizeClass}/>
                                                    <div>
                                                        Precipitation Amount
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {hourlyForecast.quantitativePrecipitation} {precipUnit}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border border-gray-light">
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiDaySunny size={20} className={screenSizeClass}/>
                                                    <div>
                                                        UV Index
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {(hourlyForecast.uvIndex !== undefined &&  hourlyForecast.uvIndex !== null)  ? `${hourlyForecast.uvIndex} out of 11` : 'Not Available' } 
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiDaySunny size={20} className={screenSizeClass}/>
                                                    <div>
                                                        Sunshine Duration
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {(hourlyForecast.sunshineDurationMinutes !== undefined && hourlyForecast.sunshineDurationMinutes !== null) ? `${hourlyForecast.sunshineDurationMinutes} minutes` : 'Not Available'} 
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${simplify ? 'p-1' : 'p-6'} text-center`}>
                                            <div className="flex flex-col items-center">
                                                <div className="flex items-center">
                                                    <WiThermometer size={20} className={screenSizeClass}/>
                                                    <div>
                                                        Dew Point
                                                    </div>
                                                </div>
                                                <div className='font-bold'>
                                                    {hourlyForecast.dewpoint}{temperatureUnit}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default HourlyForecastTableRow;
