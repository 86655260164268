import { useEffect, useRef, useState } from 'react';
import { GrUserSettings } from 'react-icons/gr';
import { MdClose } from 'react-icons/md';
import DaytimeToggle from './DaytimeToggle';
import ModelSelector from './ModelSelector';
import PastPrecipHoursSelector from './PastPrecipHoursSelector';
import TemperaturePreferencesSelector from './TemperaturePreferencesSelector';
import UnitsSelector from './UnitsSelector';
import { useRecoilState } from 'recoil';
import { defaultUserPreferences, resetPreferencesState } from '../../state/resetPreferencesState';
import { unitPreferencesState } from '../../state/unitPreferencesState';

export default function UserPreferencesComponent() {

    const [showDetails, setShowDetails] = useState(false);
    const [savePreferences, setSavePreferences] = useState(false);
    const [unitPreferences, setUnitPreferences] = useRecoilState(unitPreferencesState);
    const [currentUnitPreferences, setCurrentUnitPreferences] = useState(unitPreferences);
    const [resetPreferences, setResetPreferences] = useRecoilState(resetPreferencesState);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowDetails(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => { 
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (showDetails === true) {
            setCurrentUnitPreferences(unitPreferences);
        }
    }, [showDetails]);

    useEffect(() => {
        if (resetPreferences === true){
            setCurrentUnitPreferences(defaultUserPreferences.unitPreferences);
        }
    }, [resetPreferences]);
    
    const toggleDetails = () => {
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <div ref={ref} className="relative">
            <div>
                <div className='md:hidden flex'>
                    <button onClick={toggleDetails}>
                        User Preferences
                    </button>
                    
                </div>
                <div className='hidden md:flex'>
                    <button className='px-4 py-1 border-2 rounded-md shadow-md border-primary hover:bg-primary hover:text-white' onClick={toggleDetails}>
                        <GrUserSettings size={18}/>
                    </button>  
                </div>
               
            </div>
                

            {showDetails && (
                <div className="z-50 fixed flex inset-0 items-center justify-center w-full h-full bg-black bg-opacity-50" onClick={toggleDetails}>
                    <div className="bg-white shadow-md md:p-4 p-2 rounded-md relative border-2 border-primary text-center w-4/5 md:w-[35rem] max-h-[92%] overflow-auto" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-0 right-0 p-2" onClick={toggleDetails}>
                            <MdClose size={24} />
                        </button>
                        <h2 className="text-xl font-bold text-black md:mb-4 mb-1">User Preferences</h2>
                        <div className='flex flex-col space-y-4'>
                            <UnitsSelector currentUnitPreferences={currentUnitPreferences} setCurrentUnitPreferences={setCurrentUnitPreferences}/>
                            <DaytimeToggle savePreferences={savePreferences}/>
                            <ModelSelector savePreferences={savePreferences}/>
                            <PastPrecipHoursSelector savePreferences={savePreferences}/>
                            <TemperaturePreferencesSelector currentUnitPreferences={currentUnitPreferences} setCurrentUnitPreferences={setCurrentUnitPreferences}/>
                            <div className='flex space-x-4 justify-center'>
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md w-[11rem]"
                                    onClick={() => {
                                        setResetPreferences(true);
                                        setTimeout(() => {
                                            setResetPreferences(false);
                                        }, 1000);
                                    }}
                                >
                                    <span>Restore Defaults</span>
                                </button>
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md w-[11rem]"
                                    onClick={() => {
                                        setUnitPreferences(currentUnitPreferences);
                                        setSavePreferences(true);
                                        setTimeout(() => {
                                            setShowDetails(false);
                                        },100);
                                    }}
                                >
                                    <span>Save Settings</span>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )} 
        </div>
        
       
    );
}