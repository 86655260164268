import ClimbitLogo from '../../images/climbitlogoappstore.png';

type LoadingProps = {
    size?: string,
    type?: string,
    background?: string
}

export default function Loading({size='w-full min-h-full', type='fixed', background='bg-gray-light bg-opacity-70'}: LoadingProps) {
    return (
        <div className={`${type} ${size} ${background} flex justify-center items-center z-10 top-0`}>
            <div className="relative flex h-14 w-14 items-center justify-center">
                <div className="absolute h-full w-full rounded-full bg-gradient-to-tr from-white to-primary-dark animate-spin"></div>
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-light z-10">
                    <img src={ClimbitLogo} alt="Loading" className="h-12 w-12" />
                </div>
            </div>
        </div>
    );
}