// import { useState } from 'react';
import { WiRaindrop } from 'react-icons/wi';
import {
    BsThermometerHigh,
    BsThermometerLow
} from 'react-icons/bs';
import { getDisplayPrecipitation, getPrecipUnits, getTemperatureUnits } from '../../utils/WeatherUtils';
import { weatherCodesMap } from '../Forecast/WeatherIcons';
import { DailyForecast, ForecastMinMaxValues, HourlyForecast } from '../../types/Forecast';
import './DailyForecast.css';
import { AreaIdName } from '../../types/Area';
import { useRecoilValue } from 'recoil';
import { unitPreferencesState, precipUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';
import Tooltip from '../common/Tooltip';
import { getDisplayTime } from '../../utils/DateUtil';
import GraphSelector from '../common/GraphSelector';

type DailyForecastWidgetCardProps = {
    forecast: DailyForecast;
    id: string;
    areaName: string;
    setSelectedGraph: React.Dispatch<React.SetStateAction<string>>;
    selectedGraph: string;
    displayAreaName?: boolean;
    displayAreaLink?: boolean;
    displayDate?: boolean;
    hourlyForecasts?: HourlyForecast[];
    bestTimeToClimb?: HourlyForecast;
    onRemoveCrag?: (event: any, areaIdName: AreaIdName) => void;
    className?: string;
    onClick?: (value: string | number) => void;
    card?:any;
    isMetric?: boolean;
    forecastMinMaxValues?: ForecastMinMaxValues;
};

export default function DailyForecastWidgetCardComponent({card, forecast, areaName, setSelectedGraph, selectedGraph, displayAreaName = false, displayDate = true, hourlyForecasts, className = '', isMetric, forecastMinMaxValues }: DailyForecastWidgetCardProps) {
    const metricUnits = isMetric !== undefined ? isMetric : useRecoilValue(unitPreferencesState).metricUnits;
    const temperatureUnit = isMetric !== undefined ? getTemperatureUnits(isMetric) : useRecoilValue(temperatureUnitSelector);
    const precipUnit =  isMetric !== undefined ? getPrecipUnits(isMetric) : useRecoilValue(precipUnitSelector);

    return(
        <div ref={card} className={`${className} card-horizontal-layout h-[14rem] w-[34rem] relative`} key={`daily-forecast-horizontal-${forecast.areaId}-${forecast.time}`}>
            <div className='flex flex-row space-x-2 max-h-[14rem] max-w-[32rem]'>
                <div className='w-[14rem] p-2'>
                    <div>
                        {displayDate && (
                            <div className='card-date'>
                                <span>{forecast.displayDate}</span>
                            </div>
                        )}
                        {displayAreaName && areaName &&  (
                            <div className='card-title mx-3 flex items-center'>
                                <span className='line-clamp-2 block'>{areaName}</span>
                            </div>
                        )}

                        {(forecast && forecast.weatherCodes) && (
                            <div className='w-full'>
                                {(forecast.weatherCodes.length === 2) && (
                                    <Tooltip text={`Sunrise: ${getDisplayTime(new Date(forecast?.sunrise), true)} Sunset: ${getDisplayTime(new Date(forecast?.sunset), true)}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                                        <div className='flex w-full'>
                                            <div className='flex flex-row justify-evenly w-full items-center'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='weather-icon-position'>
                                                        {weatherCodesMap(true, 70).get(forecast?.weatherCodes[0]?.toString())}
                                                    </div>
                                                    <p>AM</p>
                                                </div>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='weather-icon-position'>
                                                        {weatherCodesMap(true, 70).get(forecast?.weatherCodes[1]?.toString())}
                                                    </div>
                                                    <p>PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                )}
                                {(forecast.weatherCodes.length === 1) && (
                                    <Tooltip text={`Sunrise: ${getDisplayTime(new Date(forecast?.sunrise), true)} Sunset: ${getDisplayTime(new Date(forecast?.sunset), true)}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                                        <div className='flex justify-center items-center flex-col'>
                                            <div className='weather-icon-position'>
                                                {weatherCodesMap(true, 70).get(forecast?.weatherCodes[0]?.toString())}
                                            </div>
                                        </div>
                                    </Tooltip>
                                )}
                            </div> 
                        )}

                        <div className='rain-wind-sun-rise-down-div'>
                            <div className='icon-styling'>
                                <WiRaindrop size={30} />
                                {getDisplayPrecipitation(forecast?.quantitativePrecipitation, metricUnits)}{precipUnit} | {forecast?.precipitationHours} hour(s)
                            </div>
                        </div>
                        <Tooltip text={`Feels Like ${Math.round(forecast.maxApparentTemperature)}${temperatureUnit}/${Math.round(forecast.minApparentTemperature)}${temperatureUnit}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                            <div className='temperature-div mt-2'>
                                <div className='temperature-high-low'>
                                    <BsThermometerHigh size={30} />
                                    {Math.round(forecast.maxTemperature)}{temperatureUnit}
                                </div>
                                <div className='temperature-high-low'>
                                    <BsThermometerLow size={30} />
                                    {Math.round(forecast.minTemperature)}{temperatureUnit}
                                </div>
                            </div>
                        </Tooltip>
                    </div>

                </div>
                <div className='w-[20rem] h-[14rem]'>
                    {hourlyForecasts && (
                        <div className='max-w-full min-w-full'>
                            <div className="flex w-full">
                                <GraphSelector hourlyForecasts={hourlyForecasts} setSelectedGraph={setSelectedGraph} selectedGraph={selectedGraph} height='h-[13rem]' isMetric={isMetric} alignment='-right-1 top-[16px]' forecastMinMaxValues={forecastMinMaxValues} />
                            </div>
                        </div>  
                    )}
                </div>       
            </div>
        </div>


    );
}
