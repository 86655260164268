import { HourlyForecast} from '../../types/Forecast';
import { LineChartData } from '../../types/LineChart';
import { _getCompareLineData, createUniqueLabels, lineColors, sortDisplayDates } from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';

type CloudCoverChartProps = {
    hourlyForecasts: HourlyForecast[];
    selectedArea?: string;
    weeklyHourlyForecast?: HourlyForecast[][];
    showTitle?: boolean;
    showWeeklyForecast: boolean;
    color?: string;
    height?: string;
    shadow?: boolean;
    padding?: string;
};

const CloudCoverChart = ({ hourlyForecasts, selectedArea, weeklyHourlyForecast, showWeeklyForecast, color, showTitle=true, height='h-[15rem]', shadow=true, padding='py-2 md:px-4 px-2' }: CloudCoverChartProps) => {    
    const uniqueLabels: string[] = createUniqueLabels(hourlyForecasts, 'displayTime');
    const repeatingLabels: string[] = weeklyHourlyForecast ? weeklyHourlyForecast[0]?.map(({displayTime}) => displayTime): [];
    const labels:string[] = showWeeklyForecast ? repeatingLabels : sortDisplayDates(uniqueLabels);
    const subLabels = showWeeklyForecast ? Array.from(new Set(weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate))) : null;

    const lineData: LineChartData[] = showWeeklyForecast ? _getCompareLineData(weeklyHourlyForecast?.flat(), 'climbit', 'climbitStars', selectedArea, 'displayTime', undefined, color)
        : _getCompareLineData(hourlyForecasts, 'climbit', 'climbitStars', selectedArea, 'displayTime', labels, color);
        
    return (
        <>
            <div className={`${padding} flex flex-col items-center justify-evenly w-full min-${height}`}>
                <div className="flex w-full">
                    {lineData  && labels && (
                        <LineChartCard title="Cloud Cover" key={'hourlyForecast_5'}
                            labels={labels}
                            lineData={[
                                {
                                    color: lineColors.cloudCoverLineColor, 
                                    labelDescription: 'Cloud Cover', 
                                    values: showWeeklyForecast && weeklyHourlyForecast
                                        ? weeklyHourlyForecast.flat().map(({ cloudCover }) => cloudCover)
                                        : hourlyForecasts.map(({ cloudCover }) => cloudCover)
                                }
                            ]}
                            yMin={0} yMax={100}
                            maintainAspectRatio={false}
                            height={height}
                            subLabels={subLabels}
                            labelSuffix='%'
                            showTitle={showTitle}
                            shadow={shadow}
                        />
                    )} 
                </div>
            </div>
        </>
    );
};

export default CloudCoverChart;
