
import AncestorLinks from '../../components/area/AncestorLinks';

type TitleProps = {
    title?: string,
    size?: string,
    header?: string
  }

export default function Title({title, header, size='text-3xl'}: TitleProps) {
    return (
        <header>
            <div className='flex flex-col items-center pb-4 md:ml-4'>
                {header &&
                <AncestorLinks path={header} />}
                <h1 className={`${size} font-bold text-center md:self-start`}>{title}</h1>
            </div>
        </header>
    );
}