
import {useEffect, useState, useRef} from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { NavLink, useNavigate } from 'react-router-dom';
import AreasComboboxComponent from '../areas/AreasCombobox';
import MobileNavBar from './MobileNavbar';
import ClimbitLogo from '../../images/climbitlogo.png';
import { Link } from 'react-router-dom';
import { AreaIdName } from '../../types/Area';
import UserPreferencesComponent from '../user/UserPreferences';
import { useLocation } from 'react-router-dom';
import { createUrlSubdomain } from '../../utils/CommonUtils';
import { City } from '../../types/City';


export default function NavbarComponent() {
    const navigate = useNavigate();
    const mobileNavRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const location = useLocation();

    const [showMobileDraw, setShowMobileDraw] = useState<boolean>(false);

    const showHideDraw = (): void => setShowMobileDraw(!showMobileDraw);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [isSearchVisible, setIsSearchVisible] = useState(true);

    const handleScroll = () => {
        const searchBarHomepage = document.getElementById('search-bar-homepage');
        if (searchBarHomepage) {
            const rect = searchBarHomepage.getBoundingClientRect();
            setIsSearchVisible(rect.top >= 0 && rect.bottom <= window.innerHeight);
        } else {
            setIsSearchVisible(false);
        }
    };

    useEffect(() => {
       
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Recalculate visibility when the location changes
        handleScroll();
    }, [location]);


    useEffect(() => {
        // Function to update window width state
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for resize event
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current && buttonRef.current.contains(event.target as HTMLElement)) {
                // Clicked on the button, do nothing
                return;
            }
            if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as HTMLElement)) {
                setShowMobileDraw(false);
            }
        };
        if (showMobileDraw) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => document.removeEventListener('click', handleClickOutside);
    }, [showMobileDraw]);

    const isHome = window.location.pathname === '/home';

    return (
        <nav className='sticky top-0 z-50'>
            <div className='z-30 relative h-[4rem] px-6 flex justify-between items-center bg-white-pure border-b-2 border-gray-light'>
                <Link to={'/home'} className='md:w-1/4 justify-start'>
                    <div className='whitespace-nowrap'>
                        {windowWidth >= 875 && (
                            <h2 className={`hidden md:inline-block text-2xl font-bold mr-2 ${isHome ? 'border-b-2 border-primary' : 'border-b-2 border-transparent hover:border-primary'}`}>Climbit</h2>
                        )}
                        <img className='h-[52px] inline-block' src={ClimbitLogo} alt='climbit logo' />
                    </div>
                   
                </Link>
                <div className='hidden md:block'>
                    <ul className='flex items-center [&>*]:mx-6  font-bold text-xl'>
                        <li>
                            <NavLink to={'/compare'} className={({isActive}) => isActive ? 'border-b-2 border-primary' : 'border-b-2 border-transparent hover:border-primary'}>
                            Compare
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/areas'} className={({isActive}) => isActive ? 'border-b-2 border-primary' : 'border-b-2 border-transparent hover:border-primary'}>
                            Explore
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className='hidden md:block items-center justify-center'>
                    <ul className='flex items-center [&>*]:mx-2 font-bold text-xl justify-center'>
                        <li>
                            <div className='relative items-center w-[350px] z-30 font-normal'>
                                {!isSearchVisible && (
                                    <AreasComboboxComponent autoFocus={false} includeCities={true} onChange={(selectedAreaCity: AreaIdName | City) => {
                                        if (selectedAreaCity && 'id' in selectedAreaCity ) {
                                            navigate(`/area/${selectedAreaCity.id}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                            window.scrollTo(0, 0);
                                        } else if (selectedAreaCity && 'countryCode' in selectedAreaCity && 'stateCode' in selectedAreaCity && 'name' in selectedAreaCity) {
                                            navigate(`/city/${selectedAreaCity.countryCode}/${selectedAreaCity.stateCode}/${selectedAreaCity.latitude}/${selectedAreaCity.longitude}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                            window.scrollTo(0, 0);
                                        }
                                     
                                    }} placeholderText='Start typing to search locations' />
                                )} 
                            </div>
                        </li>
                        <li>
                            <div className='hidden md:block pt-1 pr-1.5 font-bold [&>button]:border-2 [&>button]:rounded-md [&>button]:shadow-md [&>button]:border-primary'>
                                <UserPreferencesComponent/>
                            </div>

                        </li>
                    </ul>
                </div>

                <div className='md:hidden relative items-center w-[70%] z-30'>
                    {!isSearchVisible && (
                        <AreasComboboxComponent autoFocus={false} includeCities={true} onChange={(selectedAreaCity: AreaIdName | City) => {
                            if (selectedAreaCity && 'id' in selectedAreaCity ) {
                                navigate(`/area/${selectedAreaCity.id}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                window.scrollTo(0, 0);
                            } else if (selectedAreaCity && 'countryCode' in selectedAreaCity && 'stateCode' in selectedAreaCity && 'name' in selectedAreaCity) {
                                navigate(`/city/${selectedAreaCity.countryCode}/${selectedAreaCity.stateCode}/${selectedAreaCity.latitude}/${selectedAreaCity.longitude}/${createUrlSubdomain(selectedAreaCity.name)}`);
                                window.scrollTo(0, 0);
                            }
                        }} placeholderText='Type to search locations' />
                    )}
                </div>
                <button ref={buttonRef}  className='md:hidden' onClick={showHideDraw}><RxHamburgerMenu size={30} /></button> 
            </div>
            {showMobileDraw && (
                <>
                    <div ref={mobileNavRef} className='z-30'>
                        <MobileNavBar setShowMobileDraw={setShowMobileDraw} />
                    </div>
                    <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-20" onClick={showHideDraw}></div>
                </>
            )}
        </nav>
    );
}

