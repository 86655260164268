import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AREA, AREAS_BY_ID } from '../../graphql/areas/queries';
import { areasState } from '../../state/areasState';
import { Area } from '../../types/Area';
import CardComponent from '../cards/Card';
import AreaCardContent from '../area/AreaCardContent';
import Loading from '../common/Loading';
import Title from '../common/Title';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { createUrlSubdomain } from '../../utils/CommonUtils';


const Areas = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    const [areas, setAreas] = useRecoilState(areasState);

    const { data: currentAreaData, error, loading: areaLoading} = useQuery(AREA,  {
        variables: {
            id: params.id
        }
    });

    useEffect(() => {
        if (currentAreaData) {
            const areaNameTitle = createUrlSubdomain(currentAreaData.area.name);
            if (!params.title || params.title === 'redirect') {
                navigate(`/areas/${currentAreaData.area.id}/${areaNameTitle}`, { replace: true });
            }   
            document.title = `Explore: ${currentAreaData.area.name} | Rock Climbing Weather Forecasts`;   
        }
    }, [currentAreaData]);

    const areaChildren = currentAreaData?.area?.children?.split(',');
    document.title = `Explore: ${currentAreaData?.area?.name} | Rock Climbing Weather Forecasts`;

    const { data, loading } = useQuery(AREAS_BY_ID, {
        variables: {
            ids: areaChildren,
        },
        skip: !areaChildren
    });

    useEffect(() => {
        if (data && data.areasById && data.areasById.length > 0) {
            setAreas(data.areasById);
        }
    }, [data]);

    if (loading || areaLoading) return <Loading/>;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent/>;
    return (

        <div className="w-full bg-white-secondary min-h-screen pb-4">
            <div className='mt-4'>
                <Title title="Areas"/>
            </div>
            <main>
                <div className="mx-auto py-6 sm:px-6 lg:px-8">
                    {areas && areas.length > 0 && (
                        <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2">
                            {areas.map((area: Area) => (
                                (area.totalClimbs !== undefined && area.totalClimbs > 0 && (
                                    <div className="rounded-lg shadow-md lg:transition lg:duration-300 lg:hover:scale-105" key={`area-${area.id}-card`}>
                                        <CardComponent 
                                            title={area.name} 
                                            link={area?.isCountry || area?.isState ? `/areas/${area.id}/${createUrlSubdomain(area.name)}` : `/area/${area.id}/${createUrlSubdomain(area.name)}`} 
                                            children={<AreaCardContent showPath={true} area={area}/>}/>
                                    </div> 
                                ))
                            ))}            
                        </div>
                    )}
                </div>
            </main>
        </div> 
    );     
};

export default Areas;