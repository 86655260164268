import { Area } from '../../types/Area';
import { City } from '../../types/City';
import Tooltip from '../common/Tooltip';
import { FaMapMarkedAlt } from 'react-icons/fa';

type GoogleMapsButtonProps = {
        areaCity: Area | City
}
    
export default function GoogleMapsButton({areaCity}: GoogleMapsButtonProps) {
    return (
        <Tooltip text='Google Maps' className='text-sm text-center' position='centered mt-1'>
            <button
                className="md:px-4 px-3 py-1 border-2 rounded-md button shadow-md"
                onClick={() => {
                    window.open(areaCity.googleMaps, '_blank', 'noreferrer');
                }}
            >
                <span className="hidden md:block">Maps</span>
                <FaMapMarkedAlt className="block md:hidden" size={25} />
            </button>
        </Tooltip>
    );
}
