import { useState } from 'react';
import { FaInfo } from 'react-icons/fa6';
import { MdClose } from 'react-icons/md';

type InfoButtonProps = {
        modalContent: React.ReactNode,

}
    
export default function InfoButton({modalContent}: InfoButtonProps) {
    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };
    return (
        <>
            <button
                className="border-2 py-0.5 px-0.5 rounded-md border-primary hover:bg-primary fill-black whitespace-nowrap"
                onClick={() => setShowDetails(true)}
            >
                <FaInfo className="block" size={10} />
            </button>
            {showDetails && (
                <div className="z-50 fixed flex inset-0 items-center justify-center w-full h-full bg-black bg-opacity-50" onClick={toggleDetails}>
                    <div className="bg-white shadow-md md:p-4 p-2 rounded-md relative border-2 border-primary text-center w-4/5 md:w-[35rem] max-h-[92%] overflow-auto" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-0 right-0 p-2" onClick={(toggleDetails)}>
                            <MdClose size={24} />
                        </button>
                        <div className='flex flex-col space-y-4'>  
                            {modalContent}                            
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}