import ClimbitLogo from '../../images/climbitlogo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    const isHome = window.location.pathname === '/home';

    return (
        <footer className="bg-white-dark-secondary text-black font-bold p-4 border-t-2 border-gray-light">
            <div className="flex justify-between items-center">
                <Link to={'/home'}>
                    <h2 className={`text-2xl md:inline-block hidden font-bold mr-2 ${isHome ? 'border-b-2 border-primary' : 'border-b-2 border-transparent hover:border-primary'}`}>Climbit</h2>
                    <img className='h-[52px] inline-block' src={ClimbitLogo} alt='climbit logo' />
                </Link>

                {/* Navigation Links */}
                <div className="md:grid-cols-4 md:gap-x-8 md:gap-y-2 grid grid-cols-2 gap-x-2 gap-y-1 text-center md:text-[1rem] text-sm w-full md:w-auto">
                    <a href="https://apps.apple.com/us/app/climbit-score/id6517296523" target="_blank" rel="noopener noreferrer" className='md:order-1'>
                        iOS App
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=climbitscore.app" target="_blank" rel="noopener noreferrer" className='md:order-5'>
                        Android App
                    </a>
                    <Link to={'/home#faq'} className='md:order-3'>
                        FAQ
                    </Link>
                    <Link to={'/home#about'} className='md:order-7'>
                        About Us
                    </Link>
                    <Link to={'/privacy-policy'} onClick={() => window.scrollTo(0, 0)} className='md:order-4'>
                        Privacy Policy
                    </Link>
                    <Link to={'/terms-and-conditions'} onClick={() => window.scrollTo(0, 0)} className='md:order-8'>
                        Terms and Conditions
                    </Link>
                    <Link to={'/bug'} className='md:order-2' onClick={() => window.scrollTo(0, 0)}>
                        Report a Bug
                    </Link>
                    <Link to={'/feature'} className='md:order-6' onClick={() => window.scrollTo(0, 0)}>
                        Request a Feature
                    </Link>
                </div>
                
            </div>
        </footer>
    );
};

export default Footer;