import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// Define an enum for the shade values
export const sunShadeValues: { [key: number]: string } = {
    0: 'All Day Sun',
    1: 'AM Shade',
    2: 'PM Shade',
    3: 'All Day Shade'
};

// Define an interface for the enum state
interface SunShade {
        [id: string]: number;
}
      
// Define an atom to store the enum state
export const sunShadeState = atom<SunShade>({
    key: 'sunShadeState',
    default: {}, // Initialize as an empty object
    effects_UNSTABLE: [persistAtom]
});
      