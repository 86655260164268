import React, { ReactNode, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { GiRoundStar } from 'react-icons/gi';

interface FaqItem {
  question: string;
  answer: ReactNode;
}

interface FAQProps {
  faqs: FaqItem[];
}

const FAQ: React.FC<FAQProps> = ({ faqs }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div>
            {faqs.map((faq, index) => (
                <div key={index} className="mb-4 border-b border-gray md:mx-20 mx-4">
                    <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => toggleAccordion(index)}
                    >
                        <div className="flex items-center md:p-4 p-2 w-full justify-between">
                            <div className='flex flex-row items-center w-full'>
                                <div className='w-[3rem]'>
                                    <GiRoundStar size={20} color='orange'/>
                                </div>
                                <div className="items-start font-semibold ml-2 mr-4 text-2xl">{faq.question}</div>
                            </div>
                            
                            <div className='flex'>{activeIndex === index ? <FaAngleUp /> : <FaAngleDown />}</div>
                        </div>
                    </div>
                    {activeIndex === index && (
                        <div className="md:py-4 md:px-10 py-2 px-2 text-gray text-lg">{faq.answer}</div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQ;