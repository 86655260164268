import { useParams } from 'react-router-dom';
import Area from '../components/area/Area';
import { Helmet } from 'react-helmet';
import { undoUrlSubdomain } from '../utils/CommonUtils';

const AreaPage = () => {
    const { title } = useParams();
    const areaName = title ? undoUrlSubdomain(title) : undefined;
    // Get the current URL
    const currentUrl = window.location.href;
    return (
        <div className='min-h-screen'>
            <Helmet>
                {!currentUrl.includes('/redirect') && <link rel="canonical" href={currentUrl} />}
                <meta property="og:title" content={areaName ? `${areaName} | Climbit Weather Forecast` :  'Climbit Weather Forecast'} />
                <meta property="og:description" content={areaName ? `Climbing specific weather forecast for ${areaName}` : 'Climbing specific weather forecast'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="description" content={areaName ? `Climbing specific weather forecast for ${areaName}.` : 'Climbing specific weather forecast'} />
                <title>{areaName ? `${areaName} | Climbit Weather Forecast` : 'Climbit Weather Forecast'}</title>
            </Helmet>
            <Area/>
            <div className="flex justify-center mb-4">
                <a href="https://open-meteo.com/" target="_blank" className="text-primary no-underline hover:underline hover:text-primary-dark text-xs">Weather data by Open-Meteo.com</a>
            </div>        
        </div>
    );
};

export default AreaPage;