export const getDisplayDate = (date: Date): string  => {
    const options: any = { weekday: 'short', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', {...options});
};

export const getDisplayTime = (date: Date, minutes = false): string  => {
    const options: any = {hour: 'numeric' };

    if (minutes) {
        options.minute = 'numeric';
    }

    return date.toLocaleTimeString('en-US', {...options});
};

export const  getCurrentTimeInTimeZone = (timeZone: string): string  =>{
    const options: Intl.DateTimeFormatOptions = {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const currentTimeInTimeZone = formatter.format(new Date());
    return currentTimeInTimeZone;
};