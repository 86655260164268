
type EnableLocationServicesProps = {
    message: string,
}

export default function EnableLocationServicesComponent({message}: EnableLocationServicesProps) {

    return <div
        className="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-gradient-to-r
    from-primary
    to-primary-dark
    md:px-20
    p-4
  "
    >
        <div className="md:px-40 px-5 md:py-20 py-5 bg-white rounded-md shadow-xl">
            <div className="flex flex-col items-center">
                <h1 className="font-bold text-primary text-8xl">Error</h1>

                <h6 className="mb-2 text-2xl font-bold text-center text-gray md:text-3xl">
                    <span className="text-red">Oops!</span> You must enable location services in order to see current location!
                </h6>

                <p className="mb-8 text-center text-gray md:text-lg">
                    Error: {message}
                </p>

                <a
                    href="/"
                    className="px-6 py-2 text-sm font-semibold text-white bg-primary hover:bg-primary-dark"
                >Go home
                </a>
            </div>
        </div>
    </div>;

}



