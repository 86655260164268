import { atom, atomFamily, selectorFamily } from 'recoil';
import { Area } from '../types/Area';

export const compareAreasState = atom<Area[]>({
    key: 'compareAreasState',
    default: [],
});

export const singleAreaState = atomFamily<Area | undefined, string>({
    key: 'singleAreaState',
    default: selectorFamily<Area | undefined, string>({
        key: 'singleAreaSelector',
        get: (id: string) => ({ get }) => {
            return get(compareAreasState).find((area) => area.id.includes(id));
        },
    }),
});
