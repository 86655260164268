import { FaMountain } from 'react-icons/fa6';
import { Area } from '../../types/Area';

type MountainProjectButtonProps = {
        area: Area
}
    
export default function MountainProjectButton({area}: MountainProjectButtonProps) {
    return (
        <button
            className="md:px-4 px-3 py-1 border-2 rounded-md button shadow-md whitespace-nowrap"
            onClick={() => {
                window.open(area.mountainProject, '_blank', 'noreferrer');
            }}
        >
            <span className="hidden md:block ">Mountain Project</span>
            <FaMountain className="block md:hidden" size={25} />
        </button>
    );
}
