import Title from '../components/common/Title';

export default function TermsAndConditions() {
    document.title = 'Terms and Conditions';
    return (
        <div className="min-h-screen flex flex-col">
            <div className="bg-white">
                <div className='mt-4'>
                    <Title title="Terms and Conditions"/>
                </div>
                <div className="flex flex-col items-left px-5">
                    <p className="text-gray mb-6">
                    Welcome to Climbit! By accessing or using Climbit (the "Service"), you agree to comply with and be bound by these terms and conditions ("Terms"). Please read these Terms carefully before using Climbit.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">1. Acceptance of Terms</h2>
                    <p className="text-gray mb-6 ml-4">
                    By accessing or using Climbit, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not use Climbit.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">2. Description of Service</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit is a web application that provides climbers with access to weather forecasts, location information, and climbing condition assessments to assist in planning climbing activities.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">3. User Data and Privacy</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit collects and uses user data in accordance with our Privacy Policy, which is incorporated by reference into these Terms. By using Climbit, you consent to the collection, use, and disclosure of your data as described in the Privacy Policy.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">4. Limitation of Liability</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit is provided "as is" and without warranties of any kind, whether express or implied. In no event shall Climbit, its affiliates, partners, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">5. Hold Harmless Agreement</h2>
                    <p className="text-gray mb-6 ml-4">
                    By accessing and using this website, you expressly agree to hold harmless and indemnify the website owners, operators, and affiliates against any and all claims, damages, liabilities, losses, and expenses arising from or related to inaccuracies, errors, or omissions in the weather data or forecasts provided on the website. This agreement extends to all users of the website, including but not limited to climbers, hikers, and outdoor enthusiasts.                    </p>
                    <h2 className="text-lg font-semibold mb-2">6. Dispute Resolution</h2>
                    <p className="text-gray mb-6 ml-4">
                    Any disputes arising from or relating to these Terms or your use of Climbit shall be resolved through binding arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules. Small claims court jurisdiction is also acknowledged for disputes not covered by arbitration.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">7. Intellectual Property</h2>
                    <p className="text-gray mb-6 ml-4">
                    All content and materials on Climbit, including but not limited to text, graphics, logos, images, and software, are the property of Climbit or its licensors and are protected by copyright, trademark, and other intellectual property laws.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">8. User-Generated Content</h2>
                    <p className="text-gray mb-6 ml-4">
                    Users may contribute content to Climbit, such as comments, reviews, ratings, and customized user preferences. By contributing content, users grant Climbit a worldwide, royalty-free, perpetual, irrevocable, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in any form, media, or technology.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">9. Subscription and Payment</h2>
                    <p className="text-gray mb-6 ml-4">
                    Some features of Climbit may require payment of subscription fees. By subscribing to Climbit, you agree to pay the applicable fees and abide by the terms of the subscription, including any auto-renewal provisions.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">10. Third-Party Links and Content</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit may contain links to third-party websites or content. Climbit does not endorse or assume any responsibility for third-party content or websites, and your use of such third-party content or websites is at your own risk.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">12. Termination</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit reserves the right to terminate or suspend your access to Climbit at any time, without prior notice or liability, for any reason, including but not limited to your breach of these Terms.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">12. Governing Law</h2>
                    <p className="text-gray mb-6 ml-4">
                    These Terms and any disputes arising from or relating to these Terms or your use of Climbit shall be governed by and construed in accordance with the laws of the State of North Carolina, without regard to its conflicts of law principles.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">13. User Responsibilities and Conduct</h2>
                    <p className="text-gray mb-6 ml-4">
                    Users are responsible for their conduct and agree not to engage in activities such as spamming, hacking, or illegal activities. Users must maintain the security of their login credentials and report any unauthorized access to Climbit.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">14. Account Registration and Authentication</h2>
                    <p className="text-gray mb-6 ml-4">
                    Users must register an account and authenticate their identity to access certain features of Climbit. Users are responsible for maintaining the security of their account and login credentials.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">15. Account Termination and Data Deletion</h2>
                    <p className="text-gray mb-6 ml-4">
                    Users may terminate their account and request data deletion by accessing their account settings or contacting Climbit directly. Upon account closure, user data will be deleted in accordance with our Privacy Policy.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">16. Severability</h2>
                    <p className="text-gray mb-6 ml-4">
                    If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions of these Terms shall be unaffected and shall continue in full force and effect.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">17. Changes to Terms</h2>
                    <p className="text-gray mb-6 ml-4">
                    Climbit reserves the right to modify or revise these Terms at any time. By continuing to use Climbit after any such changes, you agree to be bound by the modified Terms. Material changes will be notified via email or other means.	
                    </p>
                    <h2 className="text-lg font-semibold mb-2">18. Contact Us</h2>
                    <p className="text-gray mb-6 ml-4">
                    If you have any questions about these Terms, please contact us at climbitscore@gmail.com.
                    </p>
                </div>
            </div>
        </div>
    );
}



