import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IdealMinMax } from '../types/Forecast';
import { defaultUserPreferences } from './resetPreferencesState';
import { getSpeedUnits, getTemperatureUnits, getPrecipUnits } from '../utils/WeatherUtils';

const { persistAtom } = recoilPersist();

export type UnitPreferences = {
    temperaturePreferences: IdealMinMax,
    metricUnits: boolean
}

export const unitPreferencesState = atom<UnitPreferences>({
    key: 'unitPreferencesState',
    default: defaultUserPreferences.unitPreferences,
    effects_UNSTABLE: [persistAtom]
});

export const temperatureUnitSelector = selector<string>({
    key: 'temperatureUnitSelector',
    get: ({ get }) => {
        const { metricUnits } = get(unitPreferencesState);
        return getTemperatureUnits(metricUnits);
    }
});

export const speedUnitSelector = selector<string>({
    key: 'speedUnitSelector',
    get: ({ get }) => {
        const { metricUnits } = get(unitPreferencesState);
        return getSpeedUnits(metricUnits);
    }
});

export const precipUnitSelector = selector<string>({
    key: 'precipUnitSelector',
    get: ({ get }) => {
        const { metricUnits } = get(unitPreferencesState);
        return getPrecipUnits(metricUnits);
    }
});

