
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ListItem } from '../../types/ListItem';
import { FaAngleDown } from 'react-icons/fa6';

type DropdownProps = {
    title: string;
    items: ListItem[]
  }

export default function Dropdown({title, items}: DropdownProps) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="px-4 py-1 border-2 rounded-md button shadow-md flex flex-row items-center">
                {title}
                <FaAngleDown
                    className="ml-2 items-center flex"
                />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform `opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 max-h-[20rem] overflow-y-scroll overflow-ellipsis origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {items.map((item) => (
                            /* Use the `active` state to conditionally style the active item. */
                            <Menu.Item key={item.title}>
                                {({ active }) => (
                                    <a
                                        href={item.link}
                                        className={`relative cursor-pointer block select-none py-2 pl-2 pr-4 ${
                                            active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                        }`}
                                    >
                                        {item.title}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>        
    );
}