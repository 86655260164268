
import {useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import UserPreferencesComponent from '../user/UserPreferences';



interface NavbarProps {
    setShowMobileDraw: React.Dispatch<React.SetStateAction<boolean>>;
  }

export default function NavbarComponent({ setShowMobileDraw }: NavbarProps) {
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (navRef.current && !navRef.current.contains(event.target as HTMLElement)) {
                setShowMobileDraw(false);
            }
        };
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div ref={navRef} className='absolute z-30 bg-white-pure w-full border-b-2 border-gray-light '>
            <ul className='p-2 md:hidden w-full [&>*]:font-bold [&>*]:mb-4 [&>li>a]:w-full [&>li>a]:block'>
                <li>
                    <Link  to={'/compare'} onClick={() =>  setShowMobileDraw(false)}>Compare </Link>   
                </li>
                <li>
                    <Link to={'/areas'} onClick={() =>  setShowMobileDraw(false)}>Explore</Link>
                </li>
                <li>
                    <UserPreferencesComponent/>
                </li>
            </ul>
        </div>
    
    );

}
