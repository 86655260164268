import { useEffect, useRef, useState } from 'react';
import { IdealMinMax } from '../../types/Forecast';
import { UnitPreferences } from '../../state/unitPreferencesState';


type TemperaturePreferencesProps = {
    currentUnitPreferences: UnitPreferences,
    setCurrentUnitPreferences: React.Dispatch<React.SetStateAction<UnitPreferences>>
}


export default function TemperaturePreferencesSelector({currentUnitPreferences, setCurrentUnitPreferences}: TemperaturePreferencesProps) {
    const [idealError, setIdealError] = useState<string | undefined>();
    const [minMaxError, setMinMaxError] = useState<string | undefined>();    

    // Ref to store the latest userTemperaturePreferences value
    const latestUserTemperaturePreferencesRef = useRef<IdealMinMax | null>(null);
    latestUserTemperaturePreferencesRef.current = currentUnitPreferences.temperaturePreferences;

    // Ref to store the latest idealError value
    const latestIdealErrorRef = useRef<string | undefined>(undefined);
    latestIdealErrorRef.current = idealError;

    // Ref to store the latest minMaxError value
    const latestMinMaxErrorRef = useRef<string | undefined>(undefined);
    latestMinMaxErrorRef.current = minMaxError;

    // Effect to update temperature preferences only when unmounting
    useEffect(() => {
        return () => {
            const latestUserTemperaturePreferences = latestUserTemperaturePreferencesRef.current;
            const latestIdealError = latestIdealErrorRef.current;
            const latestMinMaxError = latestMinMaxErrorRef.current;
            if (latestUserTemperaturePreferences) {
                if (latestIdealError || latestMinMaxError) {
                    setCurrentUnitPreferences({temperaturePreferences: currentUnitPreferences.temperaturePreferences, metricUnits: currentUnitPreferences.metricUnits });
                } else {
                    setCurrentUnitPreferences({temperaturePreferences: latestUserTemperaturePreferences, metricUnits: currentUnitPreferences.metricUnits });
                }
            }
        };
    }, []);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        const id = event.target.id;
    
        setCurrentUnitPreferences(prevPreferences => {
            let updatedPreferences = { ...prevPreferences };
    
            switch (id) {
            case 'idealMin':
                updatedPreferences = {
                    ...updatedPreferences,
                    temperaturePreferences: {
                        ...updatedPreferences.temperaturePreferences,
                        ideal: {
                            ...updatedPreferences.temperaturePreferences.ideal,
                            min: input
                        }
                    }
                  
                };
                break;
            case 'idealMax':
                updatedPreferences = {
                    ...updatedPreferences,
                    temperaturePreferences: {
                        ...updatedPreferences.temperaturePreferences,
                        ideal: {
                            ...updatedPreferences.temperaturePreferences.ideal,
                            max: input
                        }
                    }
                };
                break;
            case 'overallMin':
                updatedPreferences = {
                    ...updatedPreferences,
                    temperaturePreferences: {
                        ...updatedPreferences.temperaturePreferences,
                        minMax: {
                            ...updatedPreferences.temperaturePreferences.minMax,
                            min: input
                        }
                    }
                };
                break;
            case 'overallMax':
                updatedPreferences = {
                    ...updatedPreferences,
                    temperaturePreferences: {
                        ...updatedPreferences.temperaturePreferences,
                        minMax: {
                            ...updatedPreferences.temperaturePreferences.minMax,
                            max: input
                        }
                    }
                };
                break;
            default:
                break;
            }
    
            // Error handling
            if (isNaN(input)) {
                (id === 'idealMin' || id === 'idealMax') ? setIdealError('Please enter a valid number') : setMinMaxError('Please enter a valid number');
            } else if (updatedPreferences.temperaturePreferences.ideal.min > updatedPreferences.temperaturePreferences.ideal.max) {
                setIdealError('Ideal min must be less than ideal max value');
            } else if (updatedPreferences.temperaturePreferences.ideal.min < updatedPreferences.temperaturePreferences.minMax.min || updatedPreferences.temperaturePreferences.ideal.max > updatedPreferences.temperaturePreferences.minMax.max) {
                setIdealError('Ideal min/max must be within the overall range below');
            } else {
                setIdealError(undefined);
            }
    
            if (updatedPreferences.temperaturePreferences.minMax.min > updatedPreferences.temperaturePreferences.minMax.max) {
                setMinMaxError('Overall min must be less than overall max value');
            } else {
                setMinMaxError(undefined);
            }
            setCurrentUnitPreferences(updatedPreferences);
            return updatedPreferences;
        });
    };

    return (
        <div className='flex flex-col space-y-4'>
            <div className="flex md:flex-row flex-col items-center md:justify-between space-y-1 md:space-y-0">
                <span className='font-semibold text-[1rem] md:text-lg'>Ideal Feels Like Temp({currentUnitPreferences.metricUnits  ? '°C' : '°F'})</span>
                <div className='flex flex-row space-x-2'>
                    <input
                        type="number"
                        id="idealMin"
                        value={currentUnitPreferences.temperaturePreferences?.ideal.min}
                        onChange={(event) => handleChange(event)}
                        min={currentUnitPreferences.metricUnits ? -50 : 0}
                        max={currentUnitPreferences.temperaturePreferences?.ideal.max}
                        className="border border-gray rounded p-2 focus:ring-primary focus:border-primary font-semibold text-gray w-[6rem]"
                    />
                    <input
                        type="number"
                        id="idealMax"
                        value={currentUnitPreferences.temperaturePreferences?.ideal.max}
                        onChange={(event) => handleChange(event)}
                        min={currentUnitPreferences.temperaturePreferences?.ideal.min}
                        max={100}
                        className="border border-gray rounded p-2 focus:ring-primary focus:border-primary font-semibold text-gray w-[6rem]"
                    />

                </div>
               
            </div>
            {idealError && <p className="text-red text-sm">{idealError}</p>}
            <div className="flex md:flex-row flex-col items-center md:justify-between space-y-1 md:space-y-0">
                <span className='font-semibold md:text-lg text-[1rem]'>Tolerable Feels Like Temp({currentUnitPreferences.metricUnits  ? '°C' : '°F'})</span>
                <div className='flex flex-row space-x-2'>
                    <input
                        type="number"
                        id="overallMin"
                        value={currentUnitPreferences.temperaturePreferences?.minMax.min}
                        onChange={(event) => handleChange(event)}
                        min={currentUnitPreferences.metricUnits ? -100 : 0}
                        max={currentUnitPreferences.temperaturePreferences?.minMax.max}
                        className="border border-gray rounded p-2 focus:ring-primary focus:border-primary font-semibold text-gray w-[6rem]"
                    />
                    <input
                        type="number"
                        id="overallMax"
                        value={currentUnitPreferences.temperaturePreferences?.minMax.max}
                        onChange={(event) => handleChange(event)}
                        min={currentUnitPreferences.temperaturePreferences?.minMax.min}
                        max={100}
                        className="border border-gray rounded p-2 focus:ring-primary focus:border-primary font-semibold text-gray w-[6rem]"
                    />
                </div>
            </div>
            {minMaxError && <p className="text-red text-sm">{minMaxError}</p>}
        </div>
        
        
    );
}
