import { Switch } from '@headlessui/react';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import { defaultUserPreferences, resetPreferencesState } from '../../state/resetPreferencesState';

type DaytimeToggleProps = {
    savePreferences: boolean,
}

export default function DaytimeToggle({savePreferences}: DaytimeToggleProps) {  
    const [daytimeOnlyEnabled, setDaytimeOnlyEnabled] = useRecoilState(daytimeOnlyState);
    const resetPreferences = useRecoilValue(resetPreferencesState);
    const [isOn, setIsOn] = useState(daytimeOnlyEnabled);

    // Ref to store the latest isOn value
    const latestIsOnRef = useRef<boolean>(false);
    latestIsOnRef.current = isOn;
 
    useEffect(() => {
        return () => {
            if (savePreferences === true) {
                const latestIsOn = latestIsOnRef.current;
                setDaytimeOnlyEnabled(latestIsOn);
            }
        };   
    }, [savePreferences]);

    useEffect(() => {
        if (resetPreferences === true) {
            setIsOn(defaultUserPreferences.daytimeOnly);
        }
    }, [resetPreferences]);

    return (
        <div className="flex md:flex-row items-center md:justify-between flex-col space-y-1 md:space-y-0">
            <span className='font-semibold md:text-lg md:text-[1rem]'>Daytime Only Forecasts</span>
            <Switch
                checked={isOn}
                onChange={() => setIsOn(!isOn)}
                className={`${isOn ? 'bg-primary' : 'bg-gray opacity-70'}
                    relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
            >
                <span
                    aria-hidden="true"
                    className={`${isOn ? 'translate-x-8' : 'translate-x-0'}
                        pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    );
}