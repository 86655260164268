import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { CITY_DAILY_HOURLY_CURRENT_FORECAST } from '../../graphql/areas/queries';
import { singleAreaState, showWeeklyForecastState } from '../../state/areasState';
import Loading from '../common/Loading';
import Title from '../common/Title';
import CardCarousel from '../cards/CardCarousel';
import { navState } from '../../state/navState';
import MissingPageComponent from '../common/MissingPage';
import { Toaster } from 'react-hot-toast';
import ClimbitChart from '../charts/ClimbitChart';
import CityHeader1 from '../../images/KyleHeader.png';
import CityHeader2 from '../../images/ShockHeader.png';
import HourlyForecasts from '../Forecast/HourlyForecasts';
import DateButtons from '../buttons/DateButtons';
import { HourlyForecast, DailyForecast, PastPrecip } from '../../types/Forecast';
import PastPrecipComponent from '../Forecast/PastPrecip';
import GoogleMapsButton from '../buttons/GoogleMapsButton';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import HourlyForecastCardComponent from '../cards/HourlyForecastCard';
import HourlyForecastTable from '../Forecast/HourlyForecastTable';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { sunShadeState } from '../../state/sunShadeState';
import { Switch } from '@headlessui/react';
import { useQuery } from '@apollo/client';
import { lineColors } from '../../utils/CommonUtils';
import ShareButton from '../buttons/ShareButton';

const City = () => {
    const params: any = useParams();
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const {temperaturePreferences, metricUnits} = useRecoilValue(unitPreferencesState);
    const [isMounted, setIsMounted] = useState(false);
    const [chosenCityHeader, setChosenCityHeader] = useState('');
    const [city, setCity] = useRecoilState<any>(singleAreaState(params.id));
    const sunShade = useRecoilValue(sunShadeState);

    useEffect(() => {
        // Generate a random number between 0 and 1
        const randomIndex = Math.random() < 0.5 ? 0 : 1;
  
        // Choose the cityHeader based on the random number
        const cityHeader = randomIndex === 0 ? CityHeader1 : CityHeader2;
  
        // Set the chosenCityHeader state to the selected CityHeader
        setChosenCityHeader(cityHeader);

        // This code will run when the component mounts
        setIsMounted(true);
  
        // Cleanup function (optional)
        return () => {
            // This code will run when the component unmounts
            setIsMounted(false);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (city) {
            document.title = `${city.state}: ${city.name} | Rock Climbing Weather Forecasts`;
        }
    }, [city]);


    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
      
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]); // Run this effect whenever preferences change

    const { data, loading, error, refetch: refetchQuery } = useQuery(CITY_DAILY_HOURLY_CURRENT_FORECAST, {
        variables: {
            stateCode: params.stateCode,
            countryCode: params.countryCode,
            latitude: params.latitude,
            longitude: params.longitude,
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShade: sunShade[params.id] || 0
        }
    });

    const resetNavState = useResetRecoilState(navState);
    const dailyForecastRef = useRef<HTMLDivElement>(null);

    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
    const [currentDay, setCurrentDay] = useState<DailyForecast | null>(null);
    const [dailyForecast, setDailyForecast] = useState<DailyForecast[]>([]);
    const [hourlyForecast, setHourlyForecast] = useState<HourlyForecast[]>([]);
    const [currentHourlyForecast, setCurrentHourlyForecast] = useState<HourlyForecast | null>(null);
    const [weeklyHourlyForecast, setWeeklyHourlyForecast] = useState<HourlyForecast[][]>([]);

    const [pastPrecip, setPastPrecip] = useState<PastPrecip | undefined>(undefined);
    const bestTimesToClimb =
    city?.forecasts?.bestTimesToClimb?.hourlyForecast;
    const [showWeeklyForecast, setShowDailyForecast] = useRecoilState(showWeeklyForecastState);

    useEffect(() => {
        resetNavState();
        const isValid = !!data?.city?.forecasts;
        if (isValid) {
            setCity(data.city);
            if (data.city.forecasts.pastPrecip) setPastPrecip(data.city.forecasts.pastPrecip);
            if (data.city.forecasts.dailyForecast && data.city.forecasts.dailyForecast[0]) setCurrentDay(data.city.forecasts.dailyForecast[0]);
            if (data.city.forecasts.dailyForecast) setDailyForecast(data.city.forecasts.dailyForecast);
            if (data.city.forecasts.currentWeather) setCurrentHourlyForecast(data.city.forecasts.currentWeather);
        }
    }, [data]);

    useEffect(() => {
        if (!city) return;
        setCurrentDay(dailyForecast[currentDayIndex]);
    }, [currentDayIndex, dailyForecast, city]);

    useEffect(() => {
        if (!currentDay) return;
        setHourlyForecast(
            city?.forecasts?.hourlyForecast?.filter(
                ({ date }: HourlyForecast) => date === currentDay.date
            )
        );
        setWeeklyHourlyForecast([city?.forecasts?.hourlyForecast?.filter((_: any, i: number) => i % 4 === 0 )]);
    }, [currentDay, city]);

    if (loading) return <Loading />;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent/>;
    if (!city && !loading && isMounted) return <MissingPageComponent />;

    return (
        <div className="bg-white-secondary pb-4">
            <div className='flex flex-col justify-center items-center'>
                <div className="flex flex-col md:flex-row w-full md:justify-between md:pr-8">
                    <Title title={city?.name} header={city?.path} />
                    <div className='flex flex-col items-center justify-center md:space-y-6'>
                        <div className="flex lg:justify-start justify-center items-center">
                            {city && (
                                <div className="md:mr-6 p-2 flex gap-x-2">
                                    <Toaster
                                        position="top-center"
                                        reverseOrder={false}
                                        gutter={8}
                                        containerClassName="toast"
                                        containerStyle={{}}
                                        toastOptions={{
                                            className: '',
                                            duration: 3500,
                                            style: {
                                                background: '#363636',
                                                color: '#fff',
                                            }
                                        }}/>     
                                    {pastPrecip !== undefined && (
                                        <PastPrecipComponent pastPrecip={pastPrecip}/>
                                    )}
                                    {city?.googleMaps && (
                                        <GoogleMapsButton areaCity={city}/>
                                    )}
                                    <ShareButton title={`${city.name} | Climbit Weather Forecast`} url={window.location.href} />
                                </div>
                            )}                      
                        </div>
                    </div>
                </div>
            </div>
            
            {currentDay && (
                <>
                    <div className="flex flex-col md:flex-row justify-center md:mx-4 select-none md:mt-6">
                        <div className="flex flex-col md:flex-row pb-3 hide-scroll-bar justify-center">     
                            <div className="relative flex justify-center items-center w-full">
                                <div className="absolute inset-0 bg-black opacity-30"></div>
                                <img
                                    src={chosenCityHeader}
                                    className="rounded-lg h-full min-h-[370px] object-cover w-full hidden md:block"
                                    alt="image"
                                />
                                {currentHourlyForecast && (
                                    <>

                                        <div className={`md:absolute md:right-0 md:top-0 md:mr-20 h-full ${currentHourlyForecast.isDay ? 'md:bg-white': 'md:bg-gray-light'} justify-center items-center hidden md:flex`}>
                                            <HourlyForecastCardComponent
                                                key={currentHourlyForecast.displayTime}
                                                forecast={currentHourlyForecast}
                                                currentWeather={true}
                                                className='md:py-2'
                                                overImage={true}
                                            />
                                        </div>
                                        <div className="md:hidden relative w-full bg-white-secondary pt-4 px-2">
                                            <HourlyForecastTable
                                                forecasts={[currentHourlyForecast]}
                                                currentWeather={true}
                                            /> 
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>)}

            {dailyForecast && (
                <div className="flex flex-col p-1 hide-scroll-bar flex-shrink-0 items-center justify-center">
                    <CardCarousel dailyForecasts={dailyForecast} currentDayIndex={currentDayIndex} onClick={(id: number) => setCurrentDayIndex(id)} refToScroll={dailyForecastRef} bestTimesToClimb={bestTimesToClimb} allHourlyForecasts={city?.forecasts?.hourlyForecast}/>
                </div>)}
            <div className="flex flex-col m-auto max-w-[1300px]" id="dailyForecast" ref={dailyForecastRef}>
                {hourlyForecast && currentDay && weeklyHourlyForecast && (
                    <>
                        <div className="flex self-center md:self-end flex-row items-center mb-2 px-2 md:px-4">
                            <span className='font-semibold text-lg'>Daily</span>
                            <Switch
                                checked={showWeeklyForecast}
                                onChange={() => setShowDailyForecast(!showWeeklyForecast)}
                                className={`${showWeeklyForecast ? 'bg-primary' : 'bg-gray opacity-70'}
                    relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                            >
                                <span
                                    aria-hidden="true"
                                    className={`${showWeeklyForecast ? 'translate-x-8' : 'translate-x-0'}
                        pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                            </Switch>
                            <span className='font-semibold text-lg pl-2'>Weekly</span>
                        </div>

                        <ClimbitChart
                            hourlyForecasts={hourlyForecast}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            color={lineColors.climbitScoreLineColor}
                        />
                        <DateButtons
                            currentIndex={currentDayIndex}
                            setCurrentIndex={setCurrentDayIndex}
                            length={dailyForecast.length}
                            date={currentDay.displayDate}
                        />
                        <HourlyForecasts
                            forecasts={hourlyForecast}
                            bestTimeToClimb={
                                bestTimesToClimb
                                    ? bestTimesToClimb?.find(
                                        ({ date }: HourlyForecast) => date === currentDay.date
                                    )
                                    : undefined
                            }
                            sunrise={currentDay ? new Date(currentDay.sunrise) : undefined}
                            sunset={currentDay ? new Date(currentDay.sunset) : undefined}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default City;
