import { useParams } from 'react-router-dom';
import City from '../components/area/City';
import { Helmet } from 'react-helmet';
import { undoUrlSubdomain } from '../utils/CommonUtils';

const CityPage = () => {
    const { name } = useParams();
    const cityName = name ? undoUrlSubdomain(name) : undefined;
    // Get the current URL
    const currentUrl = window.location.href;
    return (
        <div className='min-h-screen'>
            <Helmet>
                {!currentUrl.includes('/redirect') && <link rel="canonical" href={currentUrl} />}
                <meta property="og:title" content={cityName ? `${cityName} | Climbit Weather Forecast` :  'Climbit Weather Forecast'} />
                <meta property="og:description" content={cityName ? `Climbing specific weather forecast for ${cityName}` : 'Climbing specific weather forecast'} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="description" content={cityName ? `Climbing specific weather forecast for ${cityName}.` : 'Climbing specific weather forecast'} />
                <title>{cityName ? `${cityName} | Climbit Weather Forecast` : 'Climbit Weather Forecast'}</title>
            </Helmet>
            <City/>
            <div className="flex justify-center mb-4">
                <a href="https://open-meteo.com/" target="_blank" className="text-primary no-underline hover:underline hover:text-primary-dark text-xs">Weather data by Open-Meteo.com</a>
            </div>        
        </div>
    );
};

export default CityPage;