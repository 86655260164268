import { 
    WiDaySunny, 
    WiDaySunnyOvercast,
    WiDayCloudy,
    WiCloud,
    WiFog,
    WiDayRain,
    WiRain,
    WiDaySleet,
    WiSleet,
    WiDaySnow,
    WiSnow,
    WiThunderstorm,
    WiNightClear,
    WiNightAltCloudyHigh,
    WiNightCloudy,
    WiNightRain,
    WiNightFog,
    WiNightSleet,
    WiNightSnow,
    WiNightThunderstorm
} from 'react-icons/wi';



const weatherCodesDay = (iconSize = 80) =>{
    return {
        '0': <WiDaySunny size={iconSize}/>,
        '1': <WiDaySunnyOvercast size={iconSize}/>,
        '2': <WiDayCloudy size={iconSize}/>,
        '3': <WiCloud size={iconSize}/>,
        '45': <WiFog size={iconSize}/>,
        '48': <WiFog size={iconSize}/>,
        '51': <WiDayRain size={iconSize}/>,
        '53': <WiRain size={iconSize}/>,
        '55': <WiRain size={iconSize}/>,
        '56': <WiDaySleet size={iconSize}/>,
        '57': <WiSleet size={iconSize}/>,
        '61': <WiDayRain size={iconSize}/>,
        '63': <WiRain size={iconSize}/>,
        '65': <WiRain size={iconSize}/>,
        '66': <WiDaySleet size={iconSize}/>,
        '67': <WiSleet size={iconSize}/>,
        '71': <WiDaySnow size={iconSize}/>,
        '73': <WiSnow size={iconSize}/>,
        '75': <WiSnow size={iconSize}/>,
        '77': <WiSnow size={iconSize}/>,
        '80': <WiDayRain size={iconSize}/>,
        '81': <WiRain size={iconSize}/>,
        '82': <WiRain size={iconSize}/>,
        '85': <WiDaySnow size={iconSize}/>,
        '86': <WiSnow size={iconSize}/>,
        '95': <WiThunderstorm size={iconSize}/>,
        '96': <WiThunderstorm size={iconSize}/>,
        '99': <WiThunderstorm size={iconSize}/>
    };

};

const weatherCodesNight = (iconSize = 80) =>{
    return {
        '0': <WiNightClear size={iconSize}/>,
        '1': <WiNightAltCloudyHigh size={iconSize}/>,
        '2': <WiNightCloudy size={iconSize}/>,
        '3': <WiCloud size={iconSize}/>,
        '45': <WiNightFog size={iconSize}/>,
        '48': <WiNightFog size={iconSize}/>,
        '51': <WiNightRain size={iconSize}/>,
        '53': <WiNightRain size={iconSize}/>,
        '55': <WiNightRain size={iconSize}/>,
        '56': <WiNightSleet size={iconSize}/>,
        '57': <WiNightSleet size={iconSize}/>,
        '61': <WiNightRain size={iconSize}/>,
        '63': <WiNightRain size={iconSize}/>,
        '65': <WiNightRain size={iconSize}/>,
        '66': <WiNightSleet size={iconSize}/>,
        '67': <WiNightSleet size={iconSize}/>,
        '71': <WiNightSnow size={iconSize}/>,
        '73': <WiNightSnow size={iconSize}/>,
        '75': <WiNightSnow size={iconSize}/>,
        '77': <WiNightSnow size={iconSize}/>,
        '80': <WiNightRain size={iconSize}/>,
        '81': <WiNightRain size={iconSize}/>,
        '82': <WiNightRain size={iconSize}/>,
        '85': <WiNightSnow size={iconSize}/>,
        '86': <WiNightSnow size={iconSize}/>,
        '95': <WiNightThunderstorm size={iconSize}/>,
        '96': <WiNightThunderstorm size={iconSize}/>,
        '99': <WiNightThunderstorm size={iconSize}/>
    };

};

export const weatherCodesMap = (isDay = true, size = 80) => {
    const weatherCodes = isDay ? weatherCodesDay(size) : weatherCodesNight(size);
    return new Map(Object.entries(weatherCodes));
};

