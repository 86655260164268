import { useRecoilState, useRecoilValue } from 'recoil';
import { weatherModelState } from '../../state/weatherModelState';
import { useEffect, useRef, useState } from 'react';
import { defaultUserPreferences, resetPreferencesState } from '../../state/resetPreferencesState';

type ModelSelectorProps = {
    savePreferences: boolean,
}

export default function ModelSelector({savePreferences}: ModelSelectorProps) {  
    const [weatherModel, setWeatherModel] = useRecoilState(weatherModelState);
    const [currentWeatherModel, setCurrentWeatherModel] = useState(weatherModel);
    const resetPreferences = useRecoilValue(resetPreferencesState);

    // Ref to store the latest currentWeatherModel value
    const latestCurrentWeatherModelRef = useRef<string>('best_match');
    latestCurrentWeatherModelRef.current = currentWeatherModel;

    useEffect(() => {
        return () => {
            if (savePreferences === true) {
                const latestCurrentWeatherModel = latestCurrentWeatherModelRef.current;
                setWeatherModel(latestCurrentWeatherModel);
            }
        };
    }, [savePreferences]);

    useEffect(() => {
        if(resetPreferences === true) {
            setCurrentWeatherModel(defaultUserPreferences.weatherModel);
        }
    }, [resetPreferences]);


    return (
        <div className="flex md:flex-row items-center md:justify-between space-y-1 md:space-y-0 flex-col">
            <span className='font-semibold md:text-lg text-[1rem]'>Weather Model</span>
            <select id="models" value={currentWeatherModel} onChange={e =>setCurrentWeatherModel(e.target.value)}  className="flex border border-gray text-gray text-sm rounded-lg appearance-none focus:ring-primary focus:border-primary">
                <option value="best_match">Best Match</option>
                <option value="gfs_seamless">GFS</option>
                <option value="icon_seamless">ICON</option>
                <option value="jma_seamless">JMA</option>
                <option value="gem_seamless">GEM</option>
            </select>
        </div>
    );
}